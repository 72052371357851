import { io, Socket } from "socket.io-client";

const SERVER_URL = import.meta.env.VITE_API_BASE; // Change if backend runs on another URL

export const connectSocket = (assignmentId: string): Socket => {
  return io(SERVER_URL, {
    query: { assignmentId }, // Send assignmentId as a query param
    transports: ["websocket"], // Ensure WebSocket is used
  });
};
