import { IWordbook } from "@/common/types/wordbank";
import WordbookCard from "@/pages/WordbankPage/components/wordbooks/WordbookCard";
import WordbookPage from "@/pages/WordbookPage";
import { useGetListWordbooks } from "@/services-react-query/queries/wordbank.query";
import { Box, Button, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import ListVocaByWB from "./ListVocaByWB";
import _ from "lodash";
import AppButton from "@/components/AppButton";
import PlayVocaContainer from "@/pages/WordbookPage/components/PlayVocaContainer";
import PrepareLearn from "@/pages/WordbookPage/components/LearnVoca/PrepareLearn";
interface WordbookQuestionProps {
  onClose: () => void;
}
const WordbookQuestion = ({ onClose }: WordbookQuestionProps) => {
  const { questionId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [wordBooks, setWordBooks] = useState<IWordbook[]>([]);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [wordbookActive, setWordbookActive] = useState(null);
  const [isViewList, setIsViewList] = useState(false);
  const [itemsSelected, setItemsSelected] = useState<IWordbook[]>([]);
  const [isModalLearn, setIsModalLearn] = useState(false);
  const { mutate: getListWordbooks, isLoading: loading } = useGetListWordbooks({
    onSuccess: (data: any) => {
      const sortOrder = ["Collocation", "Noun", "Verb", "Adjective", "Adverb"];
      const sortedData = [...data].sort((a, b) => {
        const indexA = sortOrder.indexOf(a.name);
        const indexB = sortOrder.indexOf(b.name);
        return indexA - indexB;
      });
      setWordBooks(sortedData);
    },
  });
  useEffect(() => {
    getListWordbooksFnc();
  }, []);
  const getListWordbooksFnc = () => {
    getListWordbooks(questionId);
  };
  if (wordbookActive) {
    return (
      <Box>
        <WordbookPage
          wordbookId={wordbookActive}
          onBack={() => {
            setWordbookActive(null);
          }}
          onlyView={true}
        />
      </Box>
    );
  }
  // console.log("wordBooks", wordBooks);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{
            fontSize: "14px",
            textTransform: "unset",
          }}
          onClick={() => {
            onClose();
          }}
        >
          Close wordbook
        </Button>
        {itemsSelected.length > 0 && isViewList && (
          <Box
            sx={{
              flex: 1,
            }}
          >
            <AppButton text="Learn" onClick={() => setIsModalLearn(true)} />
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box
            onClick={() => {
              setIsViewList(true);
            }}
            sx={{
              cursor: "pointer",
              padding: "0 4px",
              ...(isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
            }}
          >
            <ViewListIcon
              sx={{
                ...(isViewList ? { color: theme.palette.primary.main } : {}),
              }}
            />
          </Box>
          <Box
            onClick={() => {
              setIsViewList(false);
            }}
            sx={{
              cursor: "pointer",
              padding: "0 4px",
              ...(!isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
            }}
          >
            <GridViewIcon
              sx={{
                ...(!isViewList ? { color: theme.palette.primary.main } : {}),
              }}
            />
          </Box>
        </Box>
      </Box>
      {isViewList ? (
        <Box>
          {wordBooks.map((wordbook, index) => {
            return (
              <Box
                sx={{
                  padding: "8px 0",
                }}
                key={wordbook._id}
              >
                <ListVocaByWB
                  wordbook={wordbook}
                  index={index}
                  itemsSelected={itemsSelected}
                  onSelect={(items: IWordbook[]) => {
                    if (items.length === 0) {
                      return;
                    }
                    if (items.length === 1) {
                      setItemsSelected((prev: IWordbook[]) =>
                        prev.some((itemP: IWordbook) => itemP._id === items[0]._id)
                          ? prev.filter((itemP: IWordbook) => itemP._id !== items[0]._id)
                          : [...prev, items[0]]
                      );
                      return;
                    }
                    const isCheckAll =
                      items.length > 1
                        ? items?.every((item: IWordbook) =>
                            itemsSelected.find((itemS: IWordbook) => itemS._id === item._id)
                          )
                        : false;
                    setItemsSelected((prev: IWordbook[]) =>
                      isCheckAll
                        ? prev.filter((itemP: IWordbook) => !items.some((item) => item._id === itemP._id))
                        : _.uniqBy([...prev, ...items], "_id")
                    );
                  }}
                />
              </Box>
            );
          })}
        </Box>
      ) : (
        <Grid container>
          {wordBooks.map((item: IWordbook) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  padding: "10px",
                }}
              >
                <WordbookCard
                  item={item}
                  onClickItem={() => {
                    setWordbookActive(item._id);
                  }}
                  onlyView={true}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {isModalLearn && (
        <PlayVocaContainer
          onClose={() => {
            setIsModalLearn(false);
          }}
        >
          {itemsSelected.length > 0 && <PrepareLearn items={itemsSelected} />}
        </PlayVocaContainer>
      )}
    </Box>
  );
};
export default WordbookQuestion;
