import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import CreateAssignmentModal from "./CreateAssignmentModal";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { Link } from "react-router-dom";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import moment from "moment";
import { labelLearnType } from "@/pages/WordbookPage/components/LearnVoca";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
interface ClassAssignmentPageProps {
  classDetail: any;
  isTeacher?: boolean;
}
const ClassAssignmentPage = ({ classDetail, isTeacher }: ClassAssignmentPageProps) => {
  const [isCreateAssignmentDialogOpen, setIsCreateAssignmentDialogOpen] = useState(false);
  const [assignments, setAssignments] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const dataRes = await Requests.get(
      getApiUrl(ENDPOINTS.GET_VOCA_ASSIGNMENT_BY_CLASS.replace(":id", classDetail._id))
    );
    if (Array.isArray(dataRes)) {
      setAssignments(dataRes);
    }
  };
  return (
    <Box p={3}>
      {isTeacher && (
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "unset",
          }}
          onClick={() => setIsCreateAssignmentDialogOpen(true)}
        >
          Create assignment
        </Button>
      )}
      <Table stickyHeader>
        <TableHead
          sx={{
            "& th": {
              fontWeight: "bold",
              padding: "4px",
            },
          }}
        >
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Vocabulary</TableCell>
            <TableCell>Learn Type</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& td": {
              padding: "4px",
            },
          }}
        >
          {assignments.map((item) => {
            const vocabs = Array.isArray(item.vocabs)
              ? item.vocabs.map((vocab: any) => vocab.term ?? vocab.word?.word ?? "")
              : [];
            return (
              <TableRow>
                <TableCell>{item.code ?? ""}</TableCell>
                <TableCell>{item.created_at ? moment(item.created_at).format("lll") : ""}</TableCell>
                <TableCell
                  sx={{
                    fontStyle: "italic",
                    maxWidth: 240,
                    fontSize: "12px",
                  }}
                >
                  {vocabs.join(", ")}
                </TableCell>
                <TableCell
                  sx={{
                    "& p": {
                      fontStyle: "italic",
                      fontWeight: "600",
                      fontSize: 12,
                    },
                  }}
                >
                  {Array.isArray(item.learnType) ? (
                    <>
                      {item.learnType.map((type) => {
                        return <p>{labelLearnType[type]}</p>;
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell>
                  {isTeacher ? (
                    <Link to={`/teacher/voca-submission-tracking/${item._id}`}>
                      <IconButton size="small">
                        <LeaderboardIcon />
                      </IconButton>
                    </Link>
                  ) : (
                    <Link to={`/student/voca-submission/${item._id}`}>
                      <IconButton size="small">
                        <FitnessCenterIcon />
                      </IconButton>
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {isCreateAssignmentDialogOpen && (
        <CreateAssignmentModal
          onClose={() => {
            setIsCreateAssignmentDialogOpen(false);
          }}
          classDetail={classDetail}
        />
      )}
    </Box>
  );
};
export default ClassAssignmentPage;
