import { call, put, takeLatest } from "redux-saga/effects";
import { AllTypeActions } from "../types";
import { registerPublicSuccess, registerPublicFailure, RegisterPublicInput } from "../actions/auth";
import { showMessage } from "../actions/admin";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";

function* registerPublicSaga(action: { type: string; input: RegisterPublicInput }): Generator<any, void, any> {
  try {
    const apiUrl = getApiUrl(ENDPOINTS.POST_REGISTER_PUBLIC);
    const data: any = yield Requests.post(apiUrl, action.input);

    if (data && data.success) {
      yield put(registerPublicSuccess(data?.data));
      yield put(showMessage("Registration successful!", "success"));
    } else {
      yield put(registerPublicFailure(data.message));
      yield put(showMessage(data.message, "error"));
    }
    // Dispatch success action

    // Show success message
  } catch (error: any) {
    // Dispatch failure action
    yield put(registerPublicFailure(error));

    // Show error message
    yield put(showMessage(error.response?.data?.message || "Registration failed", "error"));
  }
}

export function* watchRegisterPublic() {
  yield takeLatest(AllTypeActions.POST_REGISTER_PUBLIC, registerPublicSaga);
}
