import { Box, Button, CircularProgress, TextField, Typography, Divider } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KeyTokenName, ROUTES } from "../../constants";
import { IApplicationState, SigninInput, postLogin } from "../../store-redux";
import { ERole } from "../../store-redux/interface";
import { GoogleIcon } from "@/assets/icons";

const LoginPage: React.FC = () => {
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const { handleSubmit, register, watch } = useForm<SigninInput>();
  const loginResponse = useSelector((state: IApplicationState) => state.signin);
  const dispatch = useDispatch();
  const email = watch("email");

  const onSubmit = (data: SigninInput) => {
    dispatch(postLogin(data));
  };

  const handleGoogleLogin = () => {
    window.location.href = `${import.meta.env.VITE_API_BASE}/auth/google`;
  };

  const currentInfoUser = useSelector((state: IApplicationState) => state.currentUser);

  useEffect(() => {
    const token = localStorage.getItem(KeyTokenName);
    if (token && currentInfoUser.data?.role) {
      if (currentInfoUser.data?.role === ERole.USER) {
        navigate(ROUTES.LEARN);
      } else if (currentInfoUser.data?.role === ERole.TEACHER) {
        navigate(ROUTES.TEACHER_CLASS);
      } else {
        navigate(ROUTES.TOPIC_QUESTION);
      }
    }
  }, [currentInfoUser, navigate]);

  return (
    <Box
      sx={() => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      })}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          "& .logo": {
            marginBottom: "0",
            display: "flex",
            alignItems: "center",
          },
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            width: "300px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              padding: "12px 16px 12px 12px",
              "& input": {
                marginLeft: "5px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "16px",
                padding: 0,
              },
            },
            "& .MuiButtonBase-root": {
              borderRadius: "24px",
            },
          },
        }}
      >
        <Box className="logo">
          <img width={40} height={40} src={"/svgs/logo.svg"} alt="logo" />
          <Typography
            component="span"
            sx={() => ({
              fontSize: "30px",
              fontWeight: "600",
              textTransform: "none",
              marginLeft: "14px",
              color: theme.palette.primary.main,
            })}
          >
            fongfluency lab
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={() => ({
            color: "black",
            fontSize: 36,
            fontWeight: 700,
            marginBottom: "16px",
          })}
        >
          Login
        </Typography>
        <Typography
          variant="h5"
          sx={() => ({
            color: "#000",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            marginBottom: "16px",
          })}
        >
          Please fill the details below
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          onClick={handleGoogleLogin}
          startIcon={<GoogleIcon width={24} height={24} />}
          sx={{ width: "300px", mb: 2, textTransform: "capitalize" }}
        >
          Continue with Google
        </Button>

        <Divider sx={{ width: "300px", mb: 2 }}>
          <Typography color="textSecondary">or</Typography>
        </Divider>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="email"
            required
            {...register("email")}
            InputProps={{
              startAdornment: <img src={"/svgs/person.svg"} alt="email" />,
            }}
            placeholder="Email"
          />
          {email && (
            <TextField
              placeholder="Password"
              type="password"
              required
              {...register("password")}
              InputProps={{
                startAdornment: <img src={"/svgs/key.svg"} alt="password" />,
              }}
            />
          )}
          {email && (
            <Button type="submit" variant="contained" color="secondary" disabled={loginResponse.isFetching}>
              {loginResponse.isFetching ? (
                <CircularProgress size={30} />
              ) : (
                <>
                  <img src={"/svgs/login.svg"} alt="login" />
                  <Typography
                    component="span"
                    sx={() => ({
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "none",
                      marginLeft: "14px",
                    })}
                  >
                    Login to Speak
                  </Typography>
                </>
              )}
            </Button>
          )}
          <Button variant="text" onClick={() => navigate(ROUTES.REGISTER)} sx={{ mt: 1, textTransform: "none" }}>
            Don't have an account? Register now
          </Button>
        </form>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(30, 41, 59)",
          height: "100vh",
          position: "relative",
          "& .bg-svg": {
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            "& g": {
              opacity: 0.2,
              color: "rgb(100, 116, 139)",
            },
          },
        }}
      >
        <svg
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
          className="bg-svg"
        >
          <g fill="none" stroke="currentColor" strokeWidth="100">
            <circle r="234" cx="196" cy="23"></circle>
            <circle r="234" cx="790" cy="491"></circle>
          </g>
        </svg>
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            maxWidth: "65.6rem",
            padding: "3rem",
            "& img": {
              height: "100px",
              width: "auto",
              marginBottom: "32px",
            },
          }}
        >
          <Box>
            <img src={"/images/login/banner.png"} alt="banner" />
          </Box>
          <Box
            sx={{
              color: "white",
              fontSize: "36px",
            }}
          >
            <Box>Welcome to our system</Box>
            <Box>for IELTS speaking</Box>
          </Box>
          <Box className="mt-2 text-lg leading-6 tracking-tight text-gray-400">
            The system applies a special method to help you easily pass the IELTS speaking examination.
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
