import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Grid,
  Dialog,
  IconButton,
} from "@mui/material";
import { Alert } from "@mui/lab"; // or '@mui/material/Alert' based on your MUI version
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState, showMessage } from "@/store-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";
import ClassIcon from "./../../../assets/icons/online-learning.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ClassPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const [classes, setClasses] = useState([]);
  const [open, setOpen] = useState(false);
  const [newClassTitle, setNewClassTitle] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // Fetch all classes for the teacher
  const fetchClasses = async () => {
    try {
      const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_CLASS_BY_TEACHER));
      setClasses(dataRes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  // Handle opening and closing the dialog
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle class creation
  const handleCreateClass = async () => {
    try {
      const apiUrl = getApiUrl(ENDPOINTS.CREATE_CLASS);
      try {
        await Requests.post(apiUrl, {
          title: newClassTitle,
        });
      } catch (e) {}
      //   const response = await axios.post("/class/create", {
      //     title: newClassTitle,
      //     teacherId,
      //   });
      //   setClasses([...classes, response.data]);
      fetchClasses();
      setSnackbarMessage("Class created successfully!");
      setSnackbarOpen(true);
      handleClose();
      setNewClassTitle("");
    } catch (error) {
      console.error("Error creating class:", error);
      setSnackbarMessage("Failed to create class.");
      setSnackbarOpen(true);
    }
  };

  const onCopyInviteCode = (invite_code: string) => {
    navigator.clipboard.writeText(invite_code ?? "").then(() => {
      dispatch(showMessage("Invite code copied to clipboard!", "success"));
    });
  };
  return (
    <Box p={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "4px",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
          }}
        >
          My Classes
        </Typography>
        <Button
          sx={{
            textTransform: "unset",
          }}
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          Create Class
        </Button>
      </Box>
      <Grid
        container
        sx={{
          height: "calc(100vh - 80px)",
          overflowY: "auto",
        }}
      >
        {[...classes].reverse().map((classItem) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            sx={{
              padding: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F3F3FF",
                padding: "8px",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                "& img": {
                  height: "48px",
                  width: "48px",
                  marginRight: "16px",
                },
              }}
              key={classItem._id}
              onClick={() => {
                navigate(ROUTES.TEACHER_CLASS_DETAIL.replace(":classId", classItem._id));
              }}
            >
              <img src={ClassIcon} />
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  color="primary"
                >
                  {classItem?.title ?? ""}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                      margin: "8px 0",
                      "& span": {
                        marginRight: "8px",
                      },
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onCopyInviteCode(classItem?.invite_code);
                    }}
                  >
                    <span>{classItem?.invite_code ?? ""}</span>
                    <ContentCopyIcon />
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "14px",
                  }}
                >
                  {classItem.students?.length} students
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Dialog for creating a class */}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "360px",
            maxWidth: "calc(100vw - 40px)",
          },
        }}
      >
        <DialogTitle>Create New Class</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Class Title"
            type="text"
            fullWidth
            variant="outlined"
            value={newClassTitle}
            onChange={(e) => setNewClassTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            sx={{
              textTransform: "unset",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateClass}
            color="primary"
            variant="contained"
            sx={{
              textTransform: "unset",
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClassPage;
