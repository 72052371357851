import { TabContext, TabList } from "@mui/lab";
import { Box, SxProps, Tab, Theme, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import DynamicBreadcrumbs from "../DynamicBreadcrumbs";

interface BreadcrumbItem {
  text: string;
  link?: string;
}

interface ILayoutTabProps {
  children: JSX.Element;
  tabValue?: any;
  setTabValue?: (id: number | string) => void;
  listTab: { id: number | string; label: string }[];
  title: string;
  subTitle: JSX.Element | string;
  breadcrumbs?: BreadcrumbItem[];
  sxContainer?: SxProps<Theme>;
  titleAction?: ReactNode;
}

function LayoutTab({
  children,
  tabValue,
  setTabValue,
  listTab,
  title,
  subTitle,
  breadcrumbs,
  sxContainer = {},
  titleAction,
}: ILayoutTabProps) {
  const theme = useTheme();
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue && setTabValue(newValue);
  };
  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;
  return (
    <Box sx={{ width: "100%", paddingInline: "24px", paddingBottom: 0, paddingTop: "12px", ...sxContainer }}>
      {hasBreadcrumbs && <DynamicBreadcrumbs items={breadcrumbs} />}
      {title ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
          className="layout_title_container"
        >
          <Box
            className="layout_title"
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "bold",
              marginBlock: "8px",
            }}
          >
            {title}
          </Box>
          {titleAction && titleAction}
        </Box>
      ) : null}
      {typeof subTitle === "string" ? (
        <Typography
          sx={{
            color: "#6A6969",
            fontSize: "14px",
          }}
        >
          {subTitle}
        </Typography>
      ) : (
        subTitle
      )}
      <TabContext value={tabValue}>
        {listTab.length > 0 && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: {
                  height: "2px",
                },
              }}
              sx={(theme) => ({
                "& button": {
                  textTransform: "none",
                  color: theme.palette.secondary.main,
                },
                "& .Mui-selected": {
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                },
              })}
            >
              {listTab.map((tab) => (
                <Tab label={tab.label} value={tab.id} key={tab.id} />
              ))}
            </TabList>
          </Box>
        )}
        <Box sx={{}}>{children}</Box>
      </TabContext>
    </Box>
  );
}

export default LayoutTab;
