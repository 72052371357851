import { Box } from "@mui/material";
import PersistentDrawerLeft from "./PersistentDrawerLeft";
import { ERole, IApplicationState } from "../../../store-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
// import { useCheckPersonalized } from "@/hook/useCheckPersonalized";

interface IDefaultLayoutProps {
  children: JSX.Element;
}

function DefaultLayout({ children }: IDefaultLayoutProps) {
  const navigate = useNavigate();
  const currentInfoUser = useSelector((state: IApplicationState) => state.currentUser);
  
  // Note: Temporary disable personalized when user first login
  // useCheckPersonalized();

  useEffect(() => {
    if (currentInfoUser.data) {
      if (currentInfoUser.data?.role === ERole.ADMIN || currentInfoUser.data?.role === ERole.SUPER_ADMIN) {
        navigate(ROUTES.LOGIN);
      }
    }
  }, [currentInfoUser.data, navigate]);

  return (
    <Box
      className="default-layout-view"
      sx={() => ({
        height: "100vh",
      })}
    >
      <PersistentDrawerLeft>{children}</PersistentDrawerLeft>
    </Box>
  );
}

export default DefaultLayout;
