import { Box, Button, Typography } from "@mui/material";
import { ReactNode } from "react";
import AppButton from "../AppButton";

interface AppHeaderProps {
  title: string;
  subTitle?: string;
  goBack?: () => void;
  children?: ReactNode;
}
const AppHeader = ({ title, subTitle, goBack, children }: AppHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          component="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "24px",
          }}
        >
          {title}
        </Typography>
        <Typography
          component="p"
          gutterBottom
          sx={{
            fontSize: "16px",
            marginLeft: "4px",
          }}
        >
          {subTitle}
        </Typography>
        {children && children}
      </Box>
      {goBack && (
        <AppButton
          text="Back"
          onClick={() => {
            goBack();
          }}
        />
      )}
    </Box>
  );
};

export default AppHeader;
