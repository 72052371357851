import { ROUTES } from "@/constants";
import {
  useDeleteVoca,
  useGetListVocabsByWordbook,
  useGetOneWordbook,
} from "@/services-react-query/queries/wordbank.query";
import { Box, Button, IconButton, LinearProgress, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { EAction } from "@/common";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";
import Swal from "sweetalert2";
import PlayVocaContainer from "@/pages/WordbookPage/components/PlayVocaContainer";
import PlayWithFlashCard from "@/pages/WordbookPage/components/PlayWithFlashCard";
import LearnVoca from "@/pages/WordbookPage/components/LearnVoca";
import ListVocabsGridView from "@/pages/WordbookPage/components/ListVocabsGridView";
import AddIcon from "@mui/icons-material/Add";
import WordMeaningModal from "../WordMeaningModal";
import ListVocabsListView from "@/pages/WordbookPage/components/ListVocabsListView";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import PrepareLearn from "@/pages/WordbookPage/components/LearnVoca/PrepareLearn";
import { IWordbook } from "@/common/types/wordbank";
const WordbookCMSPage = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [vocabs, setVocabs] = useState<IWordbook[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [gameType, setGameType] = useState<string | null>(null);
  const [dataWordbook, setDataWordbook] = useState(null);
  const [isViewList, setIsViewList] = useState(true);
  const { mutate: getListVocabsByWordbook, isLoading: loading } = useGetListVocabsByWordbook({
    onSuccess: (data) => {
      const sortedData = [...data].sort((a, b) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime());
      setVocabs(sortedData);
    },
  });
  // const searchParams = new URLSearchParams(location.search);
  // const { gameType } = Object.fromEntries(searchParams);

  const { mutate: getOneWordbook, isLoading: isLoadingDataWordbook } = useGetOneWordbook({
    onSuccess: (data: any) => {
      setDataWordbook(data);
    },
  });

  const { mutate: deleteVoca, isLoading: isLoadingDeleteVoca } = useDeleteVoca({
    onSuccess: (id: any) => {
      setVocabs((prev) => prev.filter((item) => item?._id !== id));
      dispatch(showMessage("Delete vocabulary success", "success"));
    },
  });
  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    getListVocabsByWordbook(params.wordbookId ?? "");
    getOneWordbook(params.wordbookId ?? "");
  };

  const onSelectGameType = (gameType: string | null) => {
    setGameType(gameType);
  };

  const showGameContent = () => {
    if (gameType === "flashcard") {
      return (
        <PlayVocaContainer
          dataWordbook={dataWordbook}
          onClose={() => {
            onSelectGameType(null);
          }}
        >
          <PlayWithFlashCard items={vocabs} />
        </PlayVocaContainer>
      );
    }
    if (gameType === "learn") {
      return (
        <PlayVocaContainer
          dataWordbook={dataWordbook}
          onClose={() => {
            onSelectGameType(null);
          }}
        >
          {vocabs.length > 0 && <PrepareLearn items={vocabs} />}
        </PlayVocaContainer>
      );
    }
    return <></>;
  };

  const onClickAction = (typeAction: EAction, item: any) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteVoca(item._id);
        }
      });
    }
  };

  return (
    <Box
      sx={() => ({
        padding: "16px",
        height: "calc(100vh - 56px)",
        "& h2": {
          height: "54px",
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "54px",
          marginRight: "10px",
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            component={"h2"}
            className="text"
            sx={{
              "& span": {
                fontWeight: 500,
                marginLeft: "10px",
                fontSize: 24,
              },
            }}
          >
            Wordbook
            {dataWordbook && <span>{dataWordbook?.name}</span>}
          </Typography>
          <IconButton onClick={() => onRefresh()}>
            <RefreshIcon />
          </IconButton>
        </Box>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            minWidth: "68px",
            height: "30px",
            padding: "0px 8px",
            borderRadius: "4px",
            color: "white",
            backgroundColor: "#264653",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "30px",
            textTransform: "none",
          }}
          variant="contained"
        >
          Back
        </Button>
      </Box>
      <Box
        sx={{
          height: "10px",
        }}
      >
        {loading && <LinearProgress />}
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Button
          sx={{
            minWidth: "68px",
            height: "30px",
            padding: "0px 8px",
            borderRadius: "4px",
            color: "white",
            backgroundColor: "#264653",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "30px",
            textTransform: "none",
          }}
          variant="contained"
          onClick={() => onSelectGameType("flashcard")}
        >
          Play with Flash Card
        </Button>
        <Button
          sx={{
            minWidth: "68px",
            height: "30px",
            padding: "0px 8px",
            borderRadius: "4px",
            color: "white",
            backgroundColor: "#264653",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "30px",
            textTransform: "none",
            margin: "0 16px",
          }}
          variant="contained"
          onClick={() => onSelectGameType("learn")}
        >
          Learn
        </Button>
        <Button
          startIcon={<AddIcon />}
          sx={{
            textTransform: "unset",
            fontSize: "16px",
            fontWeight: 700,
          }}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Nhập
        </Button>
        <Box
          sx={{
            flex: 1,
          }}
        ></Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box
            onClick={() => {
              setIsViewList(true);
            }}
            sx={{
              cursor: "pointer",
              padding: "0 4px",
              ...(isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
            }}
          >
            <ViewListIcon
              sx={{
                ...(isViewList ? { color: theme.palette.primary.main } : {}),
              }}
            />
          </Box>
          <Box
            onClick={() => {
              setIsViewList(false);
            }}
            sx={{
              cursor: "pointer",
              padding: "0 4px",
              ...(!isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
            }}
          >
            <GridViewIcon
              sx={{
                ...(!isViewList ? { color: theme.palette.primary.main } : {}),
              }}
            />
          </Box>
        </Box>
      </Box>
      {showGameContent()}

      <Box>
        {isViewList ? (
          <ListVocabsListView
            items={vocabs}
            handleClickAction={(typeAction: EAction, item: any) => onClickAction(typeAction, item)}
          />
        ) : (
          <ListVocabsGridView
            items={vocabs}
            handleClickAction={(typeAction: EAction, item: any) => onClickAction(typeAction, item)}
          />
        )}
      </Box>
      {modalOpen && (
        <WordMeaningModal
          open={modalOpen}
          onClose={(checkSuccess) => {
            setModalOpen(false);
            if (checkSuccess) {
              onRefresh();
            }
          }}
          wordbookId={dataWordbook?._id}
        />
      )}
    </Box>
  );
};

export default WordbookCMSPage;
