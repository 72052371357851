import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DateTimePicker } from "@mui/lab";
import { useState } from "react";

interface CreateAssignmentModalProps {
  onClose: () => void;
  classDetail: any;
}
const CreateAssignmentModal = ({ onClose, classDetail }: CreateAssignmentModalProps) => {
  const [data, setData] = useState({
    title: "",
    description: "",
    
  });
  const onCreateAssignment = () => {
    console.log("onCreateAssignment", classDetail);
  };
  console.log("onCreateAssignment", classDetail);

  return (
    <Modal
      open={true}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "8px",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #0000001f",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
            <AssignmentIcon />
            <Typography>Assignment</Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "unset",
            }}
            onClick={() => {
              onCreateAssignment();
            }}
          >
            Send
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 320,
              borderLeft: "1px solid #0000001f",
              padding: "16px",
              gap: "16px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Class
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                {classDetail?.title ?? ""}
              </Typography>
            </Box>

            {/* <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Deadline
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateAssignmentModal;
