import LayoutTab from "@/components/LayoutTab";
import { ROUTES } from "@/constants";
import { extractContent, sortItemOrder } from "@/helpers";
import {
  ETypeItem,
  getDetailItem,
  getListAspects,
  getListCategories,
  IApplicationState,
  parseParamUrl,
} from "@/store-redux";
import { Box, Button, Tab, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import ListSuggestionAspect from "./ListSuggestionAspect";
import MyIdeaPath from "./MyIdeaPath";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AnswerAspectToIdea from "./AnswerAspectToIdea";
import AssistantIcon from "@mui/icons-material/Assistant";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import WordbookQuestion from "./WordbookQuestion";
import DynamicBreadcrumbs from "@/components/DynamicBreadcrumbs";
import SunEditor from "suneditor-react";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AbcIcon from "@mui/icons-material/Abc";
import KeywordToIdea from "./KeywordToIdea";
import FreestyleToIdea from "./FreestyleToIdea";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

enum EAspectView {
  answer_by_aspect = "answer_by_aspect",
  sugesstion_by_aspect = "sugesstion_by_aspect",
}
const LearnQuestionPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { questionId } = useParams();

  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const listCategory = useSelector((state: IApplicationState) => state.listCategories);
  const listAspectPage = useSelector((state: IApplicationState) => state.listAspects);
  const [categorySelectedId, setCategorySelectedId] = useState<string | null>(null);
  const [aspectSelectedId, setAspectSelectedId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const typeQuestion = searchParams.get("type-question");
  const [aspectViewType, setAspectViewType] = useState<EAspectView>(EAspectView.answer_by_aspect);
  const [openWordbook, setOpenWordbook] = useState(false);
  const [openBackgroundKnowledge, setOpenBackgroundKnowledge] = useState(false);
  const [valueTab, setValueTab] = useState("1");

  useEffect(() => {
    if (questionId && detailQuestion.data?._id !== questionId) {
      dispatch(getDetailItem(questionId));
    }
    if (!listCategory.data?.length) {
      dispatch(
        getListCategories({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          type: ETypeItem.category,
          isOnlyPublic: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (listCategory?.data?.length && !categorySelectedId) {
      setCategorySelectedId(listCategory.data[0]._id);
    }
  }, [listCategory]);

  useEffect(() => {
    if (categorySelectedId) {
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          parentId: categorySelectedId,
          questionId: questionId,
          type: ETypeItem.aspect,
        })
      );
    }
  }, [categorySelectedId]);
  const listSortedCategories = useMemo(() => {
    return sortItemOrder(listCategory.data);
  }, [listCategory]);
  const categorySelected = listCategory.data?.find((item) => item._id === categorySelectedId) ?? null;

  const handleChangeTab = (_event: React.SyntheticEvent, value: any) => {
    setValueTab(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "12px",
        paddingInline: "24px",
        "& h2.layout_title": {
          fontSize: "24px",
        },
        "& .layout_title_container": {
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "column",
          "&>div": {
            flexDirection: "row",
          },
        },
        "& .panel-item": {
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* IE and Edge */,
          "& ::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
    >
      <DynamicBreadcrumbs
        items={[
          {
            text: "Learn",
            link: ROUTES.LEARN,
          },
          {
            text: `Topic & Question`,
            link:
              typeQuestion === "P2"
                ? ROUTES.LEARN_PART_2
                : parseParamUrl(ROUTES.LEARN_TOPIC_QUESTION, undefined, {
                    "type-question": typeQuestion,
                  }),
          },
          {
            text: `Ideas`,
          },
        ]}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
        className="layout_title_container"
      >
        <Box
          className="layout_title"
          sx={{
            color: "#000",
            fontSize: "20px",
            fontWeight: "bold",
            marginBlock: "8px",
          }}
        >
          {extractContent(detailQuestion.data?.name ?? "")}
        </Box>
      </Box>
      <PanelGroup direction="horizontal">
        <Panel
          defaultSize={openWordbook ? 0 : 70}
          style={{
            maxHeight: "calc(100vh - 105px)",
            overflowY: "auto",
          }}
          className="panel-item"
        >
          <TabContext value={valueTab}>
            <TabList
              onChange={handleChangeTab}
              sx={{
                "& button": {
                  textTransform: "none",
                  minHeight: "50px",
                },
              }}
            >
              <Tab label="Four-Category Approach" value="1" icon={<NaturePeopleIcon />} iconPosition="start" />
              <Tab label="Keywords to idea" value="2" icon={<AbcIcon />} iconPosition="start" />
              <Tab label="Conversation to idea" value="3" icon={<ConnectWithoutContactIcon />} iconPosition="start" />
            </TabList>
            <TabPanel value="1" style={{ padding: 0, paddingInline: "8px" }}>
              <Box
                sx={{
                  backgroundColor: "#F3F3FF",
                  borderRadius: "5px",
                  padding: "10px",
                  margin: "8px",
                  "& p": {
                    color: "#313131",
                    fontSize: "14px",
                  },
                }}
              >
                <p>
                  <b>- Miêu tả tính năng:</b> Viết ra ý tưởng để trả lời câu hỏi dựa trên từ khóa người học cung cấp với
                  từng khía cạnh của phương pháp 4 đỉnh
                  <br />
                  <b>- Đối tượng sử dụng:</b> Công cụ này phù hợp với{" "}
                  <b
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    người học chưa có ý tưởng
                  </b>{" "}
                  trả lời câu hỏi{" "}
                  <b
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    nhưng chưa có từ vựng để liên kết các ý tưởng
                  </b>{" "}
                  đó
                  <br />
                  <b>- Cách sử dụng:</b> Nhập từ, cụm từ hoặc câu trả lời vào ô trống của từng khía cạnh, bấm nút “Gen
                  idea” ở phía dưới
                </p>
              </Box>
              <LayoutTab
                listTab={listSortedCategories.map((category) => {
                  return {
                    id: category._id,
                    label: extractContent(category?.name ?? ""),
                  };
                })}
                breadcrumbs={[]}
                title={""}
                subTitle={<></>}
                tabValue={categorySelectedId ?? false}
                setTabValue={(id) => setCategorySelectedId(id as string | null)}
                titleAction={<></>}
                sxContainer={{
                  padding: 0,
                  paddingRight: "16px",
                }}
              >
                <Box>
                  {categorySelected && (
                    <Box
                      sx={{
                        display: "flex",
                        padding: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "4px 8px",
                          fontSize: "12px",
                          color: "#6A6969",
                          cursor: "pointer",
                          ...(aspectViewType === EAspectView.answer_by_aspect
                            ? {
                                color: "#2A9D8F",
                              }
                            : {}),
                        }}
                        onClick={() => {
                          setAspectViewType(EAspectView.answer_by_aspect);
                        }}
                      >
                        <QuestionAnswerIcon
                          fontSize="small"
                          sx={{
                            marginRight: "8px",
                          }}
                        />
                        Answer By Aspect
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "4px 8px",
                          fontSize: "12px",
                          color: "#6A6969",
                          cursor: "pointer",
                          ...(aspectViewType === EAspectView.sugesstion_by_aspect
                            ? {
                                color: "#2A9D8F",
                              }
                            : {}),
                        }}
                        onClick={() => {
                          setAspectViewType(EAspectView.sugesstion_by_aspect);
                        }}
                      >
                        <AssistantIcon
                          fontSize="small"
                          sx={{
                            marginRight: "8px",
                          }}
                        />
                        Suggestion By Aspect
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      paddingBottom: "16px",
                    }}
                  >
                    {categorySelected && aspectViewType === EAspectView.answer_by_aspect && (
                      <AnswerAspectToIdea
                        aspects={sortItemOrder(listAspectPage.data)}
                        question={extractContent(detailQuestion.data?.name ?? "")}
                        categorySelected={categorySelected}
                      />
                    )}
                    {categorySelected && aspectViewType === EAspectView.sugesstion_by_aspect && (
                      <>
                        {categorySelected && (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              margin: "10px 0",
                            }}
                          >
                            Choose aspects & get ideas of "{extractContent(categorySelected?.name ?? "")}"
                          </Typography>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginLeft: "-5px",
                          }}
                        >
                          {sortItemOrder(listAspectPage.data)?.map((aspect, idx: number) => {
                            return (
                              <Box
                                key={idx}
                                sx={{
                                  border: "1px solid #C6C1C1",
                                  color: "#313131",
                                  display: "flex",
                                  padding: "10px 20px",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  "&:hover": {
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    color: theme.palette.primary.main,
                                    "& .title": {
                                      fontWeight: "bold",
                                    },
                                    "& .nb_items": {
                                      color: theme.palette.primary.main,
                                      border: `1px solid ${theme.palette.primary.main}`,
                                    },
                                  },
                                  ...(aspectSelectedId === aspect._id
                                    ? {
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        color: theme.palette.primary.main,
                                        "& .title": {
                                          fontWeight: "bold",
                                        },
                                        "& .nb_items": {
                                          color: theme.palette.primary.main,
                                          border: `1px solid ${theme.palette.primary.main}`,
                                        },
                                      }
                                    : {}),
                                }}
                                onClick={() => {
                                  setAspectSelectedId(aspect._id);
                                }}
                              >
                                <Box className="title" dangerouslySetInnerHTML={{ __html: aspect.name || "" }} />
                                <Box
                                  className="nb_items"
                                  sx={{
                                    color: "#6A6969",
                                    border: "1px solid #6A6969",
                                    borderRadius: "50%",
                                    padding: "0 5px",
                                    minHeight: "24px",
                                    minWidth: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {aspect.countIdeasByAspect ?? 0}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                        {aspectSelectedId && <ListSuggestionAspect aspectId={aspectSelectedId} />}
                      </>
                    )}
                  </Box>
                </Box>
              </LayoutTab>
            </TabPanel>
            <TabPanel value="2" style={{ padding: "8px" }}>
              <KeywordToIdea question={detailQuestion?.data} />
            </TabPanel>
            <TabPanel value="3" style={{ padding: "8px" }}>
              <FreestyleToIdea question={detailQuestion?.data} />
            </TabPanel>
          </TabContext>
        </Panel>

        <PanelResizeHandle
          style={{
            width: "5px",
            backgroundColor: "#e1e1e1",
          }}
        />
        <Panel
          defaultSize={30}
          minSize={30}
          style={{
            minWidth: "320px",
            maxHeight: "calc(100vh - 105px)",
            padding: "8px",
            overflow: "auto",
          }}
          className="panel-item"
        >
          <Box sx={{}}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                marginBottom: "8px",
              }}
            >
              <Button
                variant="outlined"
                color="success"
                sx={{
                  fontSize: "14px",
                  textTransform: "unset",
                  padding: "4px",
                }}
                onClick={() => {
                  setOpenWordbook(true);
                }}
              >
                Vocabulary Suggestion
              </Button>
              {detailQuestion?.data?.backgroundKnowledge ? (
                <Button
                  variant="outlined"
                  color="info"
                  sx={{
                    fontSize: "14px",
                    textTransform: "unset",
                    padding: "4px",
                  }}
                  onClick={() => {
                    setOpenBackgroundKnowledge(true);
                  }}
                >
                  Background knowledge
                </Button>
              ) : null}
            </Box>
            {openWordbook ? (
              <Box>
                <WordbookQuestion
                  onClose={() => {
                    setOpenWordbook(false);
                  }}
                />
              </Box>
            ) : openBackgroundKnowledge ? (
              <Box
                sx={{
                  "& .sun-editor": {
                    width: "auto !important",
                    margin: "12px",
                    marginLeft: 0,
                    userSelect: "auto",
                    WebkitUserSelect: "auto",
                  },
                  "& .se-wrapper-inner": {
                    height: "100% !important",
                  },
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    fontSize: "14px",
                    textTransform: "unset",
                  }}
                  onClick={() => {
                    setOpenBackgroundKnowledge(false);
                  }}
                >
                  Close
                </Button>
                <SunEditor
                  disable={true}
                  hideToolbar
                  setContents={detailQuestion?.data?.backgroundKnowledge?.toString()}
                />
              </Box>
            ) : null}
          </Box>
          <Box
            component={"hr"}
            sx={{
              marginBlock: "8px",
            }}
          />
          <MyIdeaPath />
        </Panel>
      </PanelGroup>
    </Box>
  );
};

export default LearnQuestionPage;
