import { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useChatWithAI } from "@/services-react-query/queries";

interface Message {
  text: string;
  sender: "user" | "bot";
}

const ChatApp = () => {
  const [messages, setMessages] = useState<Message[]>(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [input, setInput] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const chatContainerRef = useRef<any>(null);

  const { mutate: chatWithAI, isLoading } = useChatWithAI({
    onSuccess(data) {
      const botMessage = { text: data?.result?.toString() ?? "", sender: "bot" };
      setMessages((prev: any) => [...prev, botMessage]);
    },
  });

  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (input.trim()) {
      const userMessage = { text: input, sender: "user" };
      setMessages((prev: any) => [...prev, userMessage]);
      setInput("");
      chatWithAI(input);
    }
  };

  const handleClearChat = () => {
    setMessages([]);
    localStorage.removeItem("chatMessages");
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied to clipboard!");
    });
  };

  return (
    <>
      {/* Button to open chat popup */}
      <IconButton
        color="primary"
        sx={{
          position: "fixed",
          top: 13,
          right: 20,
          backgroundColor: "#1976d2",
          color: "#fff",
          zIndex: 2,
          "&:hover": { backgroundColor: "#115293" },
        }}
        onClick={toggleModal}
      >
        <ChatIcon />
      </IconButton>

      {/* Chat Modal */}
      <Dialog open={isModalOpen} onClose={toggleModal} fullWidth maxWidth="lg">
        <DialogTitle>
          Chat with AI
          <IconButton
            color="default"
            onClick={toggleModal}
            sx={{ float: "right", marginTop: "-8px", marginRight: "8px" }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton color="error" onClick={handleClearChat} sx={{ float: "right", marginTop: "-8px" }}>
            <DeleteIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "70vh",
              overflowY: "auto",
              backgroundColor: "#f9f9f9",
            }}
            ref={chatContainerRef}
          >
            {messages.length > 0 ? (
              messages.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: msg.sender === "user" ? "flex-end" : "flex-start",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: msg.sender === "user" ? "#d1f7c4" : "#f1f1f1",
                      p: 1,
                      borderRadius: 2,
                      maxWidth: "80%",
                      display: "inline-block",
                      wordWrap: "break-word",
                      position: "relative",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {msg.text}
                  </Typography>
                  {msg.sender === "bot" && (
                    <IconButton size="small" onClick={() => handleCopyToClipboard(msg.text)} sx={{ ml: 1 }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              ))
            ) : (
              <Typography align="center" sx={{ mt: 3, color: "#888" }}>
                No messages yet. Start a conversation!
              </Typography>
            )}
          </Paper>
          <Box sx={{ display: "flex", mt: 2 }}>
            <TextField
              fullWidth
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
              onClick={handleSendMessage}
              endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
              disabled={isLoading}
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatApp;
