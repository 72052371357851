import {
  useDeleteVoca,
  useGetListVocabsByWordbook,
  useGetOneWordbook,
} from "@/services-react-query/queries/wordbank.query";
import { Box, Button, LinearProgress, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ListVocabsGridView from "./components/ListVocabsGridView";
import PlayWithFlashCard from "./components/PlayWithFlashCard";
import PlayVocaContainer from "./components/PlayVocaContainer";
import { EAction, ERole } from "@/common";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState, showMessage } from "@/store-redux";
import Swal from "sweetalert2";
import PrepareLearn from "./components/LearnVoca/PrepareLearn";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import ListVocabsListView from "./components/ListVocabsListView";
import CreateAssignment from "./components/LearnVoca/CreateAssignment";
import AppHeader from "@/components/AppHeader";
import AppButton from "@/components/AppButton";
import { ROUTES } from "@/constants";
import AddIcon from "@mui/icons-material/Add";
import WordMeaningModal from "../CMS/WordbankCMSPage/components/WordMeaningModal";
import { IWordbook } from "@/common/types/wordbank";

interface WordbookPageProps {
  wordbookId?: string;
  onBack?: () => void;
  onlyView?: boolean;
}
const WordbookPage = ({ wordbookId, onBack, onlyView }: WordbookPageProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [vocabs, setVocabs] = useState<IWordbook[]>([]);
  const [dataWordbook, setDataWordbook] = useState(null);
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const { mutate: getListVocabsByWordbook, isLoading: loading } = useGetListVocabsByWordbook({
    onSuccess: (data: any[]) => {
      const sortedData = [...data].sort((a, b) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime());
      setVocabs(sortedData);
    },
  });
  const [isViewList, setIsViewList] = useState(true);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [gameType, setGameType] = useState<string | null>(null);

  const { mutate: getOneWordbook, isLoading: isLoadingDataWordbook } = useGetOneWordbook({
    onSuccess: (data: any) => {
      setDataWordbook(data);
    },
  });

  const { mutate: deleteVoca, isLoading: isLoadingDeleteVoca } = useDeleteVoca({
    onSuccess: (id: any) => {
      setVocabs((prev) => prev.filter((item) => item?._id !== id));
      dispatch(showMessage("Delete vocabulary success", "success"));
    },
  });
  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    getListVocabsByWordbook(wordbookId ?? params.wordbookId ?? "");
    getOneWordbook(wordbookId ?? params.wordbookId ?? "");
  };

  const onSelectGameType = (gameType: string | null) => {
    setGameType(gameType);
  };

  const showGameContent = () => {
    if (gameType === "flashcard") {
      return (
        <PlayVocaContainer
          dataWordbook={dataWordbook}
          onClose={() => {
            onSelectGameType(null);
          }}
        >
          <PlayWithFlashCard items={vocabs} />
        </PlayVocaContainer>
      );
    }
    if (gameType === "learn") {
      return (
        <PlayVocaContainer
          dataWordbook={dataWordbook}
          onClose={() => {
            onSelectGameType(null);
          }}
        >
          {vocabs.length > 0 && <PrepareLearn items={vocabs} />}
        </PlayVocaContainer>
      );
    }
    if (gameType === "assignment") {
      return (
        <PlayVocaContainer
          dataWordbook={dataWordbook}
          onClose={() => {
            onSelectGameType(null);
          }}
        >
          {vocabs.length > 0 && (
            <CreateAssignment
              items={vocabs}
              onClose={() => {
                onSelectGameType(null);
              }}
            />
          )}
        </PlayVocaContainer>
      );
    }
    return <></>;
  };

  const onClickAction = (typeAction: EAction, item: IWordbook) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("deleted", item);
          deleteVoca(item._id);
        }
      });
    }
  };
  return (
    <Box
      sx={() => ({
        padding: "16px",
        "& h2": {
          height: "54px",
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "54px",
          marginRight: "10px",
        },
      })}
    >
      <AppHeader
        title={"Wordbook"}
        subTitle={dataWordbook?.name ?? ""}
        goBack={() => {
          if (wordbookId && onBack) {
            onBack();
            return;
          }
          if (currentUser?.data?.role === ERole.TEACHER) {
            navigate(ROUTES.TEACHER_WORDBANK);
          } else {
            navigate(ROUTES.WORDBANK);
          }
        }}
      />
      {loading && <LinearProgress />}
      <Box
        sx={{
          display: "flex",
        }}
      >
        {vocabs.length > 0 && (
          <>
            <AppButton text="Play with Flash Card" onClick={() => onSelectGameType("flashcard")} />
            <AppButton text="Learn" onClick={() => onSelectGameType("learn")} />
            {currentUser?.data?.role === ERole.TEACHER && (
              <AppButton text="Create assignment" onClick={() => onSelectGameType("assignment")} />
            )}
          </>
        )}
        {currentUser?.data?._id === dataWordbook?.owner && (
          <Button
            startIcon={<AddIcon />}
            sx={{
              textTransform: "unset",
              fontSize: "16px",
              fontWeight: 700,
            }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Nhập
          </Button>
        )}
        <Box
          sx={{
            flex: 1,
          }}
        ></Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box
            onClick={() => {
              setIsViewList(true);
            }}
            sx={{
              cursor: "pointer",
              padding: "0 4px",
              ...(isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
            }}
          >
            <ViewListIcon
              sx={{
                ...(isViewList ? { color: theme.palette.primary.main } : {}),
              }}
            />
          </Box>
          <Box
            onClick={() => {
              setIsViewList(false);
            }}
            sx={{
              cursor: "pointer",
              padding: "0 4px",
              ...(!isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
            }}
          >
            <GridViewIcon
              sx={{
                ...(!isViewList ? { color: theme.palette.primary.main } : {}),
              }}
            />
          </Box>
        </Box>
      </Box>

      {showGameContent()}
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "calc(100vh - 120px)",
        }}
      >
        {isViewList ? (
          <ListVocabsListView
            items={vocabs}
            handleClickAction={
              onlyView ? undefined : (typeAction: EAction, item: IWordbook) => onClickAction(typeAction, item)
            }
          />
        ) : (
          <ListVocabsGridView
            items={vocabs}
            handleClickAction={
              onlyView ? undefined : (typeAction: EAction, item: IWordbook) => onClickAction(typeAction, item)
            }
          />
        )}
      </Box>
      {modalOpen && (
        <WordMeaningModal
          open={modalOpen}
          onClose={(checkSuccess) => {
            setModalOpen(false);
            if (checkSuccess) {
              onRefresh();
            }
          }}
          wordbookId={dataWordbook?._id}
        />
      )}
    </Box>
  );
};

export default WordbookPage;
