import { combineReducers } from "redux";
import {
  SystemMessageReducer,
  PostLoginReducer,
  AllPermissionsReducer,
  GetCurrentUserReducer,
  GetListUserReducer,
  CreateUserReducer,
  UpdateUserReducer,
  DeleteUserReducer,
  GetRemainingEnergyReducer,
} from "./admin";
import {
  CreateItemsReducer,
  CreateQuestionsReducer,
  CreateTopicsReducer,
  DeleteItemReducer,
  DeleteQuestionReducer,
  DeleteTopicReducer,
  GetDetailItemReducer,
  GetListIdeasReducer,
  GetListQuestionsReducer,
  GetListTopicsReducer,
  UpdateItemReducer,
  UpdateQuestionReducer,
  UpdateTopicReducer,
  MyIdeaPathReducer,
  EditCustomAnswerReducer,
  SaveCustomAnswerReducer,
  GetListCategoriesReducer,
  GetListAspectsReducer,
  GenerateIdeasReducer,
  GetListAnswerOfUserReducer,
  RemoveMultipleItemsReducer,
  RemoveAnswerItemReducer,
  CreateLevelReducer,
  UpdateLevelReducer,
  GetListLevelReducer,
  UpdateOrderReducer,
  GetListAllAspectsReducer,
  UpdateIdeaCombineCategoryAspect,
  GenerateGrammarsReducer,
  GenerateStructuresReducer,
  GetListAllStructureIdeasReducer,
  GetListAllGrammarIdeasReducer,
  GetWordSearchReducer,
  CreateQuestionCategoryReducer,
  CreateQuestionTypeReducer,
  UpdateQuestionCategoryReducer,
  UpdateQuestionTypeReducer,
  GetListQuestionCategoryReducer,
  GetListQuestionTypeReducer,
  DeleteQuestionCategoryReducer,
  DeleteQuestionTypeReducer,
  GetListGuidedQuestionsReducer,
} from "./items";
import { RegisterPublicReducer } from "./auth";
import { IApplicationState } from "../interface";

export const rootReducer = combineReducers<IApplicationState>({
  signin: PostLoginReducer as any,
  currentUser: GetCurrentUserReducer as any,
  permission: AllPermissionsReducer as any,
  systemMessage: SystemMessageReducer as any,
  listUser: GetListUserReducer as any,
  createUser: CreateUserReducer as any,
  updateUser: UpdateUserReducer as any,
  deleteUser: DeleteUserReducer as any,
  listTopics: GetListTopicsReducer as any,
  createTopics: CreateTopicsReducer as any,
  createQuestions: CreateQuestionsReducer as any,
  listQuestions: GetListQuestionsReducer as any,
  deleteTopic: DeleteTopicReducer as any,
  deleteQuestion: DeleteQuestionReducer as any,
  updateTopic: UpdateTopicReducer as any,
  updateQuestion: UpdateQuestionReducer as any,
  detailItem: GetDetailItemReducer as any,
  getListIdeas: GetListIdeasReducer as any,
  getListAllGrammarIdeas: GetListAllGrammarIdeasReducer as any,
  getListAllStructureIdeas: GetListAllStructureIdeasReducer as any,
  createItems: CreateItemsReducer as any,
  deleteItem: DeleteItemReducer as any,
  updateItem: UpdateItemReducer as any,
  myIdeaPath: MyIdeaPathReducer as any,
  editCustomAnswer: EditCustomAnswerReducer as any,
  listCategories: GetListCategoriesReducer as any,
  listAspects: GetListAspectsReducer as any,
  generateIdeas: GenerateIdeasReducer as any,
  generateGrammars: GenerateGrammarsReducer as any,
  generateStructures: GenerateStructuresReducer as any,
  saveCustomAnswer: SaveCustomAnswerReducer as any,
  listAnswerOfUser: GetListAnswerOfUserReducer as any,
  removeMultipleItems: RemoveMultipleItemsReducer as any,
  removeAnswerItem: RemoveAnswerItemReducer as any,
  createLevel: CreateLevelReducer as any,
  updateLevel: UpdateLevelReducer as any,
  listLevel: GetListLevelReducer as any,
  updateOrder: UpdateOrderReducer as any,
  listAllAspects: GetListAllAspectsReducer as any,
  ideaCombineCategoryAspect: UpdateIdeaCombineCategoryAspect as any,
  wordSearch: GetWordSearchReducer as any,
  remainingEnergy: GetRemainingEnergyReducer as any,
  createQuestionCategory: CreateQuestionCategoryReducer as any,
  createQuestionType: CreateQuestionTypeReducer as any,
  updateQuestionCategory: UpdateQuestionCategoryReducer as any,
  updateQuestionType: UpdateQuestionTypeReducer as any,
  listQuestionCategory: GetListQuestionCategoryReducer as any,
  listQuestionType: GetListQuestionTypeReducer as any,
  deleteQuestionCategory: DeleteQuestionCategoryReducer as any,
  deleteQuestionType: DeleteQuestionTypeReducer as any,
  getListGuidedQuestions: GetListGuidedQuestionsReducer as any,
  registerPublic: RegisterPublicReducer as any,
});

export const ApplicationState = rootReducer;

export * from "./state";
