import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { BASE_DICT_SOUND_UK, BASE_DICT_SOUND_US } from "@/constants";
import { MenuAction } from "@/components/MenuAction";
import { EAction } from "@/common";
import { useState } from "react";
import VocabDetailModal from "../VocabDetailModal";
import { onPlayReadStr } from "@/helpers";
import React from "react";

interface VocabCardProps {
  item: {
    definition?: string;
    term?: string;
    word: {
      uid?: string;
      word: string;
      phonetic: any;
    };
  };
  handleClickAction?: (typeAction: EAction) => void;
}

const VocabsCard: React.FC<VocabCardProps> = (props) => {
  const { item, handleClickAction } = props;
  const word = item?.word;
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const onPlaySound = (type: string) => {
    if (word?.uid) {
      const baseSoundByType = {
        us: BASE_DICT_SOUND_US,
        uk: BASE_DICT_SOUND_UK,
      } as const; // Ensure baseSoundByType is treated as a constant object
      const soundUrl = `${baseSoundByType[type as "us" | "uk"]}${word.uid}.mp3`; // Type assertion for type
      const audio = new Audio(soundUrl);
      audio.play();
    } else {
      onPlayReadStr(item.term ?? word?.word, undefined, type === "us" ? "en-US" : "en-GB");
    }
  };
  // if (!word) return <></>;
  return (
    <>
      <Card
        sx={{
          cursor: "pointer",
        }}
        onClick={() => setIsOpenDetail(true)}
      >
        <CardContent
          sx={{
            padding: "10px!important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                flex: 1,
                fontWeight: "bold",
              }}
            >
              {item.term ?? word.word ?? ""}
            </Typography>
            {handleClickAction && (
              <MenuAction
                handleClick={(typeAction: EAction) => {
                  handleClickAction(typeAction);
                }}
                hide={[EAction.edit]}
              />
            )}
          </Box>
          <Box
            sx={() => ({
              "& .phonetic_type": {
                fontWeight: "bold",
              },
              "& .phonetic_text": {},
            })}
          >
            <Box
              sx={() => ({
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                "& span:first-of-type": {
                  fontWeight: "bold",
                },
              })}
            >
              <span>US</span>
              <IconButton
                size="small"
                onClick={(e) => {
                  onPlaySound("us");
                  e.stopPropagation();
                }}
              >
                <VolumeUpIcon />
              </IconButton>
              {word && (
                <span>{word.phonetic?.us ? (word.uid ? `/${word.phonetic?.us}/` : `${word.phonetic?.us}`) : ""}</span>
              )}
            </Box>
            <Box
              sx={() => ({
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                "& span:first-of-type": {
                  fontWeight: "bold",
                },
              })}
            >
              <span>UK</span>
              <IconButton
                size="small"
                onClick={(e) => {
                  onPlaySound("uk");
                  e.stopPropagation();
                }}
              >
                <VolumeUpIcon />
              </IconButton>
              {word && (
                <span>{word.phonetic?.uk ? (word.uid ? `/${word.phonetic?.uk}/` : `${word.phonetic?.uk}`) : ""}</span>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      {isOpenDetail && (
        <VocabDetailModal
          word={
            item.term
              ? {
                  ...word,
                  word: item.term,
                  content: [
                    {
                      text: item.definition,
                    },
                  ],
                }
              : word
              ? word
              : {
                  word: item.term,
                  content: [
                    {
                      text: item.definition,
                    },
                  ],
                }
          }
          onClose={() => setIsOpenDetail(false)}
        />
      )}
    </>
  );
};

export default VocabsCard;
