import { IWordbook } from "@/common/types/wordbank";
import ListVocabsListView from "@/pages/WordbookPage/components/ListVocabsListView";
import { useGetListVocabsByWordbook } from "@/services-react-query/queries/wordbank.query";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

interface ListVocaByWBProps {
  wordbook: IWordbook;
  itemsSelected: IWordbook[];
  onSelect: (items: IWordbook[]) => void;
  index: number;
}
const ListVocaByWB = ({ wordbook, itemsSelected, onSelect, index }: ListVocaByWBProps) => {
  const theme = useTheme();
  const [vocabs, setVocabs] = useState<IWordbook[]>([]);
  const { mutate: getListVocabsByWordbook, isLoading: loading } = useGetListVocabsByWordbook({
    onSuccess: (data: any[]) => {
      const sortedData = [...data].sort((a, b) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime());
      setVocabs(sortedData);
    },
  });
  useEffect(() => {
    getListVocabsByWordbook(wordbook._id);
  }, []);
  //   console.log("vocabs", vocabs);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <IconButton
          onClick={() => {
            onSelect && onSelect(vocabs);
          }}
          sx={{ color: theme.palette.primary.main }}
        >
          {vocabs?.every((item: IWordbook) => itemsSelected.find((itemS) => itemS._id === item._id)) ? (
            <CheckBoxIcon />
          ) : (
            <CheckBoxOutlineBlankIcon />
          )}
        </IconButton>
        <Box
          sx={{
            padding: "0px 4px",
            backgroundColor: "#2A9D8F",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "700",
            borderRadius: "50%",
            minWidth: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "8px",
          }}
        >
          {index + 1}
        </Box>
        <Typography>{wordbook?.name ?? ""}</Typography>
      </Box>
      <ListVocabsListView items={vocabs} itemsSelected={itemsSelected} onSelect={onSelect} />
    </Box>
  );
};

export default ListVocaByWB;
