import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KeyTokenName, ROUTES } from "../../constants";
import { postRegisterPublic } from "../../store-redux/actions/auth";
import { IApplicationState } from "../../store-redux/interface";
import { ERole } from "../../store-redux/interface";
import EmailIcon from "@mui/icons-material/Email";

interface RegisterInput {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const RegisterPage: React.FC = () => {
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<RegisterInput>();
  const dispatch = useDispatch();
  const currentInfoUser = useSelector((state: IApplicationState) => state.currentUser);
  const registerPublicData = useSelector((state: IApplicationState) => state.registerPublic);
  const registerResponse = registerPublicData;
  const onSubmit = (data: RegisterInput) => {
    const { name, email, password } = data;

    dispatch(
      postRegisterPublic({
        username: name,
        email,
        password,
        expiredTime: "",
        active: false,
        role: ERole.USER,
      })
    );
  };

  useEffect(() => {
    if (registerPublicData?.registerResponse?.data) {
      navigate(ROUTES.LOGIN);
    }
  }, [registerPublicData, navigate]);

  return (
    <Box
      sx={() => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      })}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          "& .logo": {
            marginBottom: "0",
            display: "flex",
            alignItems: "center",
          },
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            width: "300px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              padding: "12px 16px 12px 12px",
              "& input": {
                marginLeft: "5px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "16px",
                padding: 0,
              },
            },
            "& .MuiButtonBase-root": {
              borderRadius: "24px",
            },
          },
        }}
      >
        <Box className="logo">
          <img width={40} height={40} src={"/svgs/logo.svg"} alt="logo" />
          <Typography
            component="span"
            sx={() => ({
              fontSize: "30px",
              fontWeight: "600",
              textTransform: "none",
              marginLeft: "14px",
              color: theme.palette.primary.main,
            })}
          >
            fongfluency lab
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={() => ({
            color: "black",
            fontSize: 36,
            fontWeight: 700,
            marginBottom: "16px",
          })}
        >
          Register
        </Typography>
        <Typography
          variant="h5"
          sx={() => ({
            color: "#000",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            marginBottom: "16px",
          })}
        >
          Please fill the details below
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="text"
            required
            {...register("name", { required: "Name is required" })}
            InputProps={{
              startAdornment: <img src={"/svgs/person.svg"} alt="person" />,
            }}
            placeholder="Username"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <TextField
            type="email"
            required
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            InputProps={{
              startAdornment: <EmailIcon />,
            }}
            placeholder="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            placeholder="Password"
            type="password"
            required
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters",
              },
            })}
            InputProps={{
              startAdornment: <img src={"/svgs/key.svg"} alt="password" />,
            }}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <TextField
            placeholder="Confirm Password"
            type="password"
            required
            {...register("confirmPassword", {
              required: "Please confirm your password",
              validate: (value) => value === watch("password") || "Passwords do not match",
            })}
            InputProps={{
              startAdornment: <img src={"/svgs/key.svg"} alt="confirm password" />,
            }}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
          />
          <Button type="submit" variant="contained" color="secondary" disabled={registerResponse.isFetching}>
            {registerResponse.isFetching ? (
              <CircularProgress size={30} />
            ) : (
              <Typography
                component="span"
                sx={() => ({
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "none",
                  marginLeft: "14px",
                })}
              >
                Register
              </Typography>
            )}
          </Button>
          <Button variant="text" onClick={() => navigate(ROUTES.LOGIN)} sx={{ mt: 1, textTransform: "none" }}>
            Already have an account? Login
          </Button>
        </form>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(30, 41, 59)",
          height: "100vh",
          position: "relative",
          "& .bg-svg": {
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            "& g": {
              opacity: 0.2,
              color: "rgb(100, 116, 139)",
            },
          },
          "& .icon-top-svg": {},
        }}
      >
        <svg
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
          className="bg-svg"
        >
          <g fill="none" stroke="currentColor" strokeWidth="100">
            <circle r="234" cx="196" cy="23"></circle>
            <circle r="234" cx="790" cy="491"></circle>
          </g>
        </svg>
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            maxWidth: "65.6rem",
            padding: "3rem",
            "& img": {
              height: "100px",
              width: "auto",
              marginBottom: "32px",
            },
          }}
        >
          <Box>
            <img src={"/images/login/banner.png"} />
          </Box>
          <Box
            sx={{
              color: "white",
              fontSize: "36px",
            }}
          >
            <Box>Welcome to our system</Box>
            <Box>for IELTS speaking</Box>
          </Box>
          <Box className="mt-2 text-lg leading-6 tracking-tight text-gray-400">
            The system applies a special method to help you easily pass the IELTS speaking examination.
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterPage;
