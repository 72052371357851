import RemainingEnergy from "@/components/RemainingEnergy";
import { KeyTokenName, ROUTES, appVersion } from "@/constants";
import { getUrlServer } from "@/helpers";
import { ERole, IApplicationState } from "@/store-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import Person2Icon from "@mui/icons-material/Person2";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import * as React from "react";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ClassIcon from "@mui/icons-material/Class";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import AssignmentIcon from "@mui/icons-material/Assignment";

const drawerWidth = 250;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface IPersistentDrawerLeftProps {
  children: JSX.Element;
}

export default function PersistentDrawerLeft({ children }: IPersistentDrawerLeftProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const role = currentUser?.data?.role;
  const ListItemNav =
    role === ERole.TEACHER
      ? [
          {
            title: "Class",
            icon: <ClassIcon />,
            path: ROUTES.TEACHER_CLASS,
          },
          {
            title: "Wordbank",
            icon: <SpellcheckIcon />,
            path: ROUTES.TEACHER_WORDBANK,
          },
          {
            title: "Voca assignment",
            icon: <AssignmentIcon />,
            path: ROUTES.TEACHER_VOCA_ASSIGNMENT,
          },
        ]
      : role === ERole.USER
      ? [
          {
            title: "Learn",
            icon: <LocalLibraryIcon />,
            path: ROUTES.LEARN,
          },
          {
            title: "Profile",
            icon: <Person2Icon />,
            path: ROUTES.PROFILE,
          },
          {
            title: "Wordbank",
            icon: <SpellcheckIcon />,
            path: ROUTES.WORDBANK,
          },
          {
            title: "My class",
            icon: <ClassIcon />,
            path: ROUTES.STUDENT_MY_CLASS,
          },
        ]
      : [];

  const valueFromParam = (): number => {
    if (role === ERole.TEACHER) {
      if (pathname.indexOf("teacher/class") !== -1) {
        return 0;
      } else if (pathname.indexOf("teacher/wordbank") !== -1 || pathname.indexOf("wordbook") !== -1) {
        return 1;
      } else if (
        pathname.indexOf("teacher/voca-assignment") !== -1 ||
        pathname.indexOf("teacher/voca-submission-tracking") !== -1
      ) {
        return 2;
      }
      return 0;
    }
    if (pathname.indexOf("learn") !== -1) {
      return 0;
    } else if (pathname.indexOf("challenge") !== -1) {
      return 1;
    } else if (pathname.indexOf("personalized") !== -1) {
      return 0;
    } else if (pathname.indexOf("profile") !== -1) {
      return 1;
    } else if (pathname.indexOf("wordbank") !== -1 || pathname.indexOf("wordbook") !== -1) {
      return 2;
    } else if (pathname.indexOf("student/my-class") !== -1 || pathname.indexOf("student/class") !== -1) {
      return 3;
    }
    return 0;
  };

  const [value, setValue] = React.useState<number>(valueFromParam());

  React.useEffect(() => {
    setValue(valueFromParam());
  }, [pathname, role]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem(KeyTokenName);
        navigate("/login");
        window.location.href = "/login";
      }
    });
  };

  useEffect(() => {
    if (pathname.indexOf("answer-question") !== -1) {
      open && handleDrawerClose();
    }
  }, [pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      {!open && (
        <Box
          sx={{
            height: "100vh",
            width: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginTop: 1.2, marginLeft: 3, ...(open ? { display: "none" } : {}) }}
          >
            <MenuIcon htmlColor="#000" />
          </IconButton>
        </Box>
      )}
      {/* {!open && (
        <AppBar
          // position="fixed"
          open={open}
          sx={{
            width: "fit-content",
            position: "fixed",
            left: 0,
            top: 0,
            backgroundColor: "transparent !important",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ ...(open ? { display: "none" } : {}) }}
            >
              <MenuIcon htmlColor="#000" />
            </IconButton>
          </Toolbar>
        </AppBar>
      )} */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
          },
          "& .MuiPaper-root": {
            backgroundColor: "#f4f4f6",
            color: "white",
            "& .MuiListItemIcon-root": {
              minWidth: "32px",
            },
            "& .MuiListItem-root": {
              minWidth: "32px",
              color: "#000",
              borderRadius: "8px",
              margin: "10px 0",
              "& span": {
                fontSize: "14px",
              },
              "& svg": {
                fontSize: "22px",
                color: "#000",
              },
              "&:hover": {
                color: theme.palette.primary.main,
                "& svg": {
                  fontSize: "22px",
                  color: theme.palette.primary.main,
                },
              },
              "&.active": {
                color: theme.palette.primary.main,
                transition: "border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                backgroundColor: "#fff",
                "& svg": {
                  color: theme.palette.primary.main,
                },
              },
            },
            "& svg": {
              fontSize: "20px",
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "32px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& img": {
                height: "40px",
                marginRight: "8px",
              },
            }}
          >
            <img width={28} height={28} src={"/svgs/logo.svg"} />
            <Typography
              variant="h6"
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#2A9D8F",
              }}
            >
              fongfluency lab
            </Typography>
          </Box>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? <ChevronLeftIcon htmlColor="#000" /> : <ChevronRightIcon htmlColor="#000" />}
            </IconButton>
          </DrawerHeader>
        </Box>

        <List
          sx={{
            padding: "0 10px",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingBlock: "10px",
            }}
          >
            <Avatar
              sx={{
                height: "48px",
                width: "48px",
                marginRight: "5px",
              }}
              src={
                currentUser.data?.avatarPath ? `${getUrlServer(currentUser.data?.avatarPath)}` : "/images/people.png"
              }
            />
            <Box
              sx={{
                width: "calc(100% - 55px)",
              }}
            >
              <Box display={"flex"} gap={1}>
                <Box sx={{ color: "#000", fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden" }}>
                  {currentUser.data?.username}
                </Box>
                <RemainingEnergy />
              </Box>

              <Box
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {currentUser.data?.email}
              </Box>
            </Box>
          </Box>

          {ListItemNav.map((item, index) => (
            <Fragment key={index}>
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  navigate(item.path);
                  setValue(index);
                }}
                className={clsx(value === index && "active")}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            </Fragment>
          ))}
        </List>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            margin: "0 10px",
            padding: "10px 20px",
          }}
          onClick={() => {
            handleLogout();
          }}
        >
          <LogoutIcon sx={{ color: "#6A6969" }} />
          <Typography
            sx={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "14px",
              marginLeft: "10px",
            }}
          >
            Sign out
          </Typography>
        </Box>
        <Typography
          component={"h3"}
          sx={{
            fontSize: "12px",
            padding: "10px 20px",
            margin: "0 10px",
            color: "#6A6969",
          }}
        >
          {appVersion}
        </Typography>
      </Drawer>
      <Main open={open}>
        {/* <DrawerHeader /> */}
        {children}
      </Main>
    </Box>
  );
}
