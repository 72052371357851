import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import PrepareLearn from "./PrepareLearn";
import { useEffect, useState } from "react";
import { showMessage } from "@/store-redux";
import { useDispatch } from "react-redux";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
interface CreateAssignmentProps {
  items: any[];
  onClose: () => void;
}
const CreateAssignment = ({ items, onClose }: CreateAssignmentProps) => {
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [dataClass, setDataClass] = useState(null);

  const dispatch = useDispatch();
  const onCreateAssignment = async (data: any) => {
    setLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.CREATE_VOCA_ASSIGNMENT);

    try {
      const resData = await Requests.post(apiUrl, {
        vocabs: Array.isArray(data.items) ? data.items.map((item: any) => item._id) : [],
        learnType: Array.isArray(data.learnTypes) ? data.learnTypes : [],
        ...(dataClass ? { class: dataClass._id } : {}),
      });
      onClose();
      dispatch(showMessage("Create assignment success", "success"));
    } catch (e) {
      console.log("e", e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_CLASS_BY_TEACHER));
      setClasses(dataRes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };
  console.log("classes", classes);
  return (
    <PrepareLearn
      items={items}
      isCreateAssignment={true}
      onSave={(data) => {
        onCreateAssignment(data);
      }}
      actionNode={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Autocomplete
            disablePortal
            options={classes.map((item) => ({
              ...item,
              value: item._id,
              label: `${item.title} #${item.invite_code}`,
            }))}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Class" />}
            value={dataClass}
            onChange={(event, value) => {
              setDataClass(value);
            }}
          />
        </Box>
      }
    />
  );
};
export default CreateAssignment;
