import { ENDPOINTS, getApiUrl, IItems, Requests } from "@/common";
import { cleanQuestion, extractContent, randomStringFromDatetime } from "@/helpers";
import { Box, Button, CircularProgress, Input } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import ListIdeaAI from "./ListIdeaAI";
import { useSelector } from "react-redux";
import { IApplicationState } from "@/store-redux";

interface AnswerAspectToIdeaProps {
  aspects: any[];
  question: string;
  categorySelected: IItems;
}

const AnswerAspectToIdea = ({ aspects, question, categorySelected }: AnswerAspectToIdeaProps) => {
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const contentQuestion = extractContent(detailQuestion.data?.name ?? "");

  const [answerByAspect, setAnswerByAspect] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [idea, setIdea] = useState<any>(null);
  const keyword = useMemo(() => {
    return Object.keys(answerByAspect).reduce((prev, val) => {
      return `${prev} ${answerByAspect[val] ?? ""}\n`;
    }, "");
  }, [answerByAspect]);
  const onGenIdeas = async () => {
    setIsLoading(true);

    try {
      const apiUrl = getApiUrl(ENDPOINTS.KEYWORD_TO_IDEA);
      const res = await Requests.post(apiUrl, {
        question,
        keyword,
      });

      if (res.data?.result?.length) {
        const item = res.data?.result[0];
        setIdea({
          genByAI: true,
          _id: randomStringFromDatetime(),
          name: `${item.title} (${item.detail})`,
          description: item.example_answer,
          locationIdea: "",
        });
      }
    } catch (e) {
      /* empty */
    }
    setIsLoading(false);
  };

  const getRandomColor = useCallback(() => {
    const randomInt = Math.floor(Math.random() * 0xffffff);
    const randomColor = `#${randomInt.toString(16).padStart(6, "0")}`;
    return randomColor;
  }, []);

  return (
    <Box>
      <Box>
        {aspects.map((aspect, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  fontWeight: "bold",
                  marginBottom: "4px",
                }}
              >
                {(() => {
                  const text = extractContent(aspect.name || "");
                  const matches = text.match(/^(\d+\.\s*)?(.)(.*)$/);
                  if (!matches) return text;
                  const [, , firstChar, restChars] = matches;
                  const restCharsMatch = restChars ? restChars.match(/^(.*?)(\s*\(.*\))?\s*$/) : null;
                  const [mainText, bracketsText] = restCharsMatch ? restCharsMatch.slice(1) : ["", ""];

                  return (
                    <>
                      <span style={{ color: "black", fontSize: "20px", fontWeight: "400" }}>{idx + 1}. </span>
                      <span style={{ color: getRandomColor(), fontSize: "22px" }}>{firstChar}</span>
                      <span style={{ color: "black" }}>
                        {mainText}
                        <span style={{ fontWeight: "400" }}>
                          {bracketsText?.replace(")", "") || ""}
                          {` của ${
                            categorySelected?.description
                              ? extractContent(categorySelected?.description)
                              : extractContent(categorySelected.name)
                          } ảnh hưởng thế nào đến ${
                            detailQuestion?.data?.description
                              ? extractContent(detailQuestion?.data?.description)
                              : cleanQuestion(contentQuestion)
                          })`}
                        </span>
                      </span>
                    </>
                  );
                })()}
              </Box>
              <Input
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  padding: "8px",
                  "&::after": {
                    display: "none",
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                placeholder="Enter my answer ..."
                value={answerByAspect[aspect._id] ?? ""}
                onChange={(event) => {
                  setAnswerByAspect((prev: any) => ({
                    ...prev,
                    [aspect._id]: event.target.value,
                  }));
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            onGenIdeas();
          }}
          variant="contained"
          color="primary"
          sx={{
            textTransform: "unset",
          }}
          disabled={isLoading || keyword.trim() === ""}
        >
          {isLoading && (
            <CircularProgress
              size={20}
              sx={{
                marginRight: "10px",
              }}
            />
          )}
          Gen ideas
        </Button>
      </Box>
      {idea && <ListIdeaAI ideas={[idea]} />}
    </Box>
  );
};
export default AnswerAspectToIdea;
