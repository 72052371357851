import versionText from "../../version.txt"; // Adjust the path as necessary
import configs from "@/configs.json";
export const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  GOOGLE_AUTH_CALLBACK: "/auth/google/callback",
  TOPIC_QUESTION: "/cms/topic-question",
  CATEGORY_ASPECT: "/cms/category-aspect",
  QUESTION_IDEA: "/cms/question-idea/:id",
  USERS: "/cms/users",
  CHALLENGE_CMS: "/cms/challenge",
  LEARN: "/learn",
  LEARN_TOPIC: "/learn/topic",
  LEARN_GROUP_PART2: "/learn/group-part2",
  LEARN_QUESTION_PART2: "/learn/group-part2/:groupId",
  LEARN_GUIDED_QUESTION_PART2: "/learn/question-part2/:questionId",
  LEARN_QUESTION: "/learn/question/:topicId",
  LEARN_CATEGORY: "/learn/category/:topicId/:questionId",
  LEARN_ASPECT: "/learn/aspect/:topicId/:questionId/:categoryId",
  LEARN_IDEA: "/learn/idea/:topicId/:questionId/:categoryId/:aspectId",
  ANSWER: "/answer/:topicId/:questionId/:categoryId/:aspectId",
  CHALLENGE: "/challenge",
  PROFILE: "/profile",
  WORDBANK: "/wordbank",
  WORDBOOK: "/wordbook/:wordbookId",
  ASPECT_ALL_CMS: "/cms/aspect-all",
  PERSONALIZED_CMS: "/cms/personalized",
  STRUCTURE_CMS: "/cms/structure",
  AI_CALL_STATIS_CMS: "/cms/ai-call-statis",
  GRAMMAR_CMS: "/cms/grammar",
  LLM_ENERGY_CMS: "/cms/llm-energy",
  DICT_REPORT_CMS: "/cms/dict-report",
  QUESTION_PART2: "/cms/question-part2",
  QUESTION_TYPE_CMS: "/cms/question-type",
  CHOOSE_ORIENTED_LEARNING: "/learn/choose-oriented-learning",
  LEARN_QUESTION_TYPE: "/learn/question-type",
  LEARN_QUESTION_TYPE_BY_CAT: "/learn/question-type/:questionCategoryId",
  LEARN_QUESTION_TYPE_LIST_QUESTION: "/learn-question-type/:questionTypeId/list-question",
  LEARN_TOPIC_QUESTION: "/learn/topic-question",
  LEARN_QUESTION_PAGE: "/learn/by-question/:questionId",
  ANSWER_QUESTION: "/answer-question/:questionId",
  LEARN_PART_2: "/learn/part-2",
  TEACHER_CLASS: "/teacher/class",
  TEACHER_CLASS_DETAIL: "/teacher/class/:classId",
  TEACHER_WORDBANK: "/teacher/wordbank",
  TEACHER_VOCA_ASSIGNMENT: "/teacher/voca-assignment",
  // Submission
  STUDENT_VOCA_SUBMISSTION: "/student/voca-submission/:assignmentId",
  TEACHER_VOCA_SUBMISSION_TRACKING: "/teacher/voca-submission-tracking/:assignmentId",

  STUDENT_MY_CLASS: "/student/my-class",
  STUDENT_CLASS_DETAIL: "/student/class/:classId",

  CMS_LEARN_VOCA: "/cms/clearn-voca",
  CMS_LEARN_VOCA_QUESTION: "/cms/clearn-voca/:questionId",

  CMS_WORDBANK: "/cms/wordbank",
  CMS_WORKBOOK: "/cms/workbook/:wordbookId",
  CMS_QUESTION_WORDBOOK: "/cms/question-wordbook/:questionId",
};

export const KeyTokenName = "ffl_token";

export const appVersion = `Version: ${(await fetch(versionText).then((res) => res.text())) || "0.0.1"}`;

export const BASE_DICT_SOUND_US = import.meta.env.BASE_DICT_SOUND_US || configs.BASE_DICT_SOUND_US;
export const BASE_DICT_SOUND_UK = import.meta.env.BASE_DICT_SOUND_UK || configs.BASE_DICT_SOUND_UK;

export const INIT_GRAMMAR_TOPICS = [
  "Hiện tại đơn",
  "Hiện tại tiếp diễn",
  "Quá khứ đơn",
  "Quá khứ tiếp diễn",
  "Hiện tại hoàn thành",
  "Hiện tại hoàn thành tiếp diễn",
  "Quá khứ hoàn thành",
  "Quá khứ hoàn thành tiếp diễn",
  "Tương lai đơn",
  "Tương lai tiếp diễn",
  "Be going to",
  "Giới từ chỉ địa điểm",
  "Giới từ chỉ thời gian",
  "Câu bị động",
  "Danh từ đếm được",
  "Danh từ không đếm được",
  "Mạo từ",
  "Đại từ",
  "Mệnh đề quan hệ",
  "Động từ khuyết thiếu",
  "Mệnh đề chỉ mục đích",
  "Mệnh đề chỉ nguyên nhân",
  "Mệnh đề chỉ lí do",
  "Mệnh đề chỉ kết quả",
  "Mệnh đề chỉ sự nhượng bộ",
  "Liên từ",
  "So sánh hơn",
  "So sánh hơn nhất",
  "Câu điều kiện",
  "Wish/ If only",
  "Gerund and Infinitive",
];

export enum LLMTypes {
  add_connector = "add_connector",
  repharse = "repharse",
  adjust_word_limit = "adjust_word_limit",
  grammar_analysis = "grammar_analysis",
  suggest_vocabulary = "suggest_vocabulary",
  sort_idea = "sort_idea",
  search_dict = "search_dict",
  transcribe = "transcribe",
  evaluate = "evaluate",
}

export const INIT_LLM_ENERGY = {
  limitQuotaEnergyByDay: 1000,
  types: {
    [LLMTypes.add_connector]: 20, // claude-3-5-sonnet
    [LLMTypes.repharse]: 20, // claude-3-5-sonnet
    [LLMTypes.adjust_word_limit]: 20, // claude-3-haiku => claude-3-5-sonnet
    [LLMTypes.grammar_analysis]: 20, // gpt-4 => claude-3-5-sonnet
    [LLMTypes.suggest_vocabulary]: 8, // llama-v3
    [LLMTypes.sort_idea]: 8, // llama-v3
    [LLMTypes.search_dict]: 8, // llama-v3
    [LLMTypes.transcribe]: 8, // nova-2
    [LLMTypes.evaluate]: 8, // llama-v3
  },
};

export enum EQuestionNumber {
  QUESTION_PART_1 = "P1",
  QUESTION_PART_2 = "P2",
  QUESTION_PART_3 = "P3",
}

export enum VocaType {
  noun = "noun",
  verb = "verb",
  adjective = "adjective",
  adverb = "adverb",
  phrases = "phrases",
  collocation = "collocation",
  idiom = "idiom",
}

export const colorByLevel = {
  A1: "#90EE90",
  A2: "#ADD8E6",
  B1: "#FFD700",
  B2: "#FFA500",
  C1: "#ff0000",
  C2: "#800080",
};
