import { AllTypeActions } from "../types";
import { ERole, IUser } from "../../common/types/user";

export interface RegisterPublicInput {
  username: string;
  email: string;
  password: string;
  expiredTime?: string;
  active?: boolean;
  role?: ERole;
}

export const postRegisterPublic = (input: RegisterPublicInput): any => {
  return {
    type: AllTypeActions.POST_REGISTER_PUBLIC,
    input,
  };
};

export const registerPublicSuccess = (data: IUser): any => {
  return {
    type: AllTypeActions.REGISTER_PUBLIC_SUCCESS,
    data,
  };
};

export const registerPublicFailure = (error: any): any => {
  return {
    type: AllTypeActions.REGISTER_PUBLIC_FAILURE,
    error,
  };
};
