import { AllTypeActions } from "../types";
import { put, takeLatest } from "redux-saga/effects";
import { Requests } from "@/common/requests";
import { getApiUrl, ENDPOINTS } from "@/common/endpoints";
import { ERole, showMessage } from "..";
import { KeyTokenName } from "@/constants";

function* postLogin({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.POST_LOGIN);
    const data: any = yield Requests.post(apiUrl, input, false);
    if (data?.data) {
      localStorage.setItem(KeyTokenName, data?.data.token);
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
      yield put({ type: `${AllTypeActions.GET_CURRENT_USER}` });
      yield put(showMessage(data.message, "success"));
    } else {
      yield put(showMessage(data.message, "error"));
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    put(showMessage(e.toString(), "error"));
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* getCurrentUser({ type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.GET_CURRENT_USER);
    const data: any = yield Requests.get(apiUrl);
    if (data && data.success) {
      if (!data.data.active && data.data.role !== ERole.SUPER_ADMIN) {
        // Force logout if user is inactive
        localStorage.removeItem(KeyTokenName);
        window.location.href = "/";
        return;
      }
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
      // force logout
      localStorage.removeItem(KeyTokenName);
      window.location.href = "/";
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
    // force logout
    localStorage.removeItem(KeyTokenName);
    window.location.href = "/";
  }
}

function* getListUser({ type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  ``;
  try {
    const apiUrl = getApiUrl(ENDPOINTS.GET_LIST_USER);
    const data: any = yield Requests.get(apiUrl);
    if (data && data?.data) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* createUser({ type, input }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.POST_REGISTER);
    const data: any = yield Requests.post(apiUrl, input);
    if (data && data.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
      yield put(showMessage(data.message, "success"));
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
      yield put(showMessage(data.message, "error"));
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* updateUser({ type, input }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const { _id, ...rest } = input;
    const apiUrl = getApiUrl(ENDPOINTS.ONE_AUTH, { id: _id }, true);
    const data: any = yield Requests.patch(apiUrl, rest);
    if (data?.success) {
      yield put(showMessage("Update item success", "success"));
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* deleteUser({ id, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.ONE_AUTH, { id }, true);
    const data: any = yield Requests.delete(apiUrl);
    if (data?.success) {
      yield put(showMessage("Delete item success", "success"));
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* getRemainingEnergy({ type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.GET_REMAINING_ENERGY);
    const data: any = yield Requests.get(apiUrl);
    if (data && data.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

export function* adminActions() {
  yield takeLatest(AllTypeActions.POST_LOGIN, postLogin);
  yield takeLatest(AllTypeActions.GET_CURRENT_USER, getCurrentUser);
  yield takeLatest(AllTypeActions.GET_LIST_USER, getListUser);
  yield takeLatest(AllTypeActions.CREATE_USER, createUser);
  yield takeLatest(AllTypeActions.UPDATE_USER, updateUser);
  yield takeLatest(AllTypeActions.DELETE_USER, deleteUser);
  yield takeLatest(AllTypeActions.GET_REMAINING_ENERGY, getRemainingEnergy);
}
