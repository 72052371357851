import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Tabs, Tab, Button, LinearProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";
import { ROUTES } from "@/constants";
import ClassAssignmentPage from "@/pages/Teacher/ClassDetailPage/components/ClassAssignmentPage";
import ClassLessonPage from "@/pages/Teacher/ClassDetailPage/components/ClassLessonPage";
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StudentClassDetailPage = () => {
  const navigate = useNavigate();
  const { classId } = useParams();
  const dispatch = useDispatch();
  const [classDetail, setClassDetail] = useState(null);
  const [tabActive, setTabActive] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabActive(newValue);
  };

  const fetchClassDetail = async () => {
    try {
      const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_ONE_CLASS.replace(":classId", classId ?? "")));
      setClassDetail(dataRes);
    } catch (error) {
      console.error("Error fetching class detail:", error);
    }
  };

  const onDelete = () => {
    Swal.fire({
      title: "Are you sure to delete it?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("on delete");
      }
    });
  };
  useEffect(() => {
    fetchClassDetail();
  }, [classId]);

  const onCopyInviteCode = () => {
    navigator.clipboard.writeText(classDetail?.invite_code ?? "").then(() => {
      dispatch(showMessage("Invite code copied to clipboard!", "success"));
    });
  };

  if (!classDetail)
    return (
      <Box>
        <LinearProgress />
      </Box>
    );

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              textTransform: "none",
              marginRight: "8px",
            }}
            onClick={() => {
              navigate(ROUTES.STUDENT_MY_CLASS);
            }}
          >
            Back
          </Button>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            Class: {classDetail.title}
          </Typography>
        </Box>
        {/* <IconButton onClick={() => onDelete()}>
          <DeleteIcon />
        </IconButton> */}
      </Box>
      <Box
        sx={{
          backgroundColor: "#F3F3FF",
          padding: "8px",
          borderRadius: "8px",
        }}
      >
        <Typography>
          Teacher: <strong>{classDetail.teacher.username}</strong>
        </Typography>
        <Typography>
          Invite Code: <strong>{classDetail.invite_code}</strong>
          <IconButton
            onClick={() => {
              onCopyInviteCode();
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabActive}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-flexContainer": {
              "& button": {
                textTransform: "none",
                fontSize: "16px",
              },
            },
          }}
        >
          <Tab label="Lessons" {...a11yProps(0)} />
          <Tab label="Assignments" {...a11yProps(1)} />
        </Tabs>
        <Box>
          {tabActive === 0 && <ClassLessonPage classId={classId} students={classDetail.students} />}
          {tabActive === 1 && <ClassAssignmentPage classDetail={classDetail} />}
        </Box>
      </Box>
    </Box>
  );
};

export default StudentClassDetailPage;
