import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useDispatch } from "react-redux";
import { getApiUrl, ENDPOINTS } from "../../common/endpoints";
import { Requests } from "../../common/requests";
import { KeyTokenName } from "../../constants";
import { getCurrentUser } from "../../store-redux";

const InactiveAccountMessage = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      gap: 3,
      padding: 2,
      textAlign: "center",
    }}
  >
    <Typography variant="h5" color="error">
      Your account is not active yet
    </Typography>
    <Typography variant="body1">
      If you want to activate your account, please contact thầy Phong
    </Typography>
    <Button variant="contained" onClick={() => window.location.href = ROUTES.LOGIN}>
      Back to Login
    </Button>
  </Box>
);

const GoogleAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");

        if (!code) {
          navigate(ROUTES.LOGIN);
          return;
        }

        const apiUrl = getApiUrl(ENDPOINTS.GOOGLE_AUTH_CALLBACK);
        const response = await Requests.post(apiUrl, { code }, false);

        if (response?.data?.token) {
          if (!response.data.active) {
            setIsInactive(true);
            return;
          }
          localStorage.setItem(KeyTokenName, response.data.token);
          dispatch(getCurrentUser());
          navigate(ROUTES.LEARN);
        } else {
          navigate(ROUTES.LOGIN);
        }
      } catch (error) {
        console.error("Google auth callback error:", error);
        navigate(ROUTES.LOGIN);
      }
    };

    handleCallback();
  }, [location, navigate, dispatch]);

  if (isInactive) {
    return <InactiveAccountMessage />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: 2,
      }}
    >
      <CircularProgress size={40} />
      <Typography variant="h6">Completing login...</Typography>
    </Box>
  );
};

export default GoogleAuthCallback; 