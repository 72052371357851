import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { ROUTES } from "@/constants";
import { getUrlServer } from "@/helpers";
import { connectSocket } from "@/socket";
import { Avatar, Box, Button, Grid, Paper, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Socket } from "socket.io-client";

const VocaSubmissionTracking = () => {
  const navigate = useNavigate();
  const { assignmentId } = useParams();
  const [assignment, setAssignment] = useState(null);
  const [submissions, setSubmissions] = useState([]);

  let socket: Socket;

  useEffect(() => {
    getDataAssignment();
    getSubmission();
  }, []);

  const getDataAssignment = async () => {
    const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_VOCA_ASSIGNMENT.replace(":id", assignmentId)));
    if (dataRes) {
      setAssignment(dataRes);
    }
  };

  const getSubmission = async () => {
    try {
      const url = getApiUrl(ENDPOINTS.GET_LIST_VOCA_SUBMISSION_BY_ASSIGNMENT.replace(":id", assignmentId ?? ""));

      const data = await Requests.get(url);
      if (Array.isArray(data)) {
        setSubmissions(data);
      }
    } catch (error) {
      console.error("Error fetching helpers:", error);
    }
  };

  useEffect(() => {
    socket = connectSocket(assignmentId);

    socket.on("connect", () => {
      console.log("Connected with socket ID:", socket.id);
    });

    socket.on("submissionUpdate", (data: { message: string }) => {
      if (data?.submission && data?.submission?.assignment === assignmentId) {
        setSubmissions((prev) => _.uniqBy([data?.submission, ...prev], "_id"));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const submissionsViewing = useMemo(() => {
    let data = submissions.map((item, index) => {
      const allQ = Object.keys(item.answers).length * Object.keys(item.answers[Object.keys(item.answers)[0]]).length;
      let correctAns = 0;
      Object.values(item.answers).forEach((value) => {
        correctAns += Object.values(value).filter((item) => item === true).length;
      });
      const percent = (100 * correctAns) / allQ;
      return {
        ...item,
        percent,
        correctAns,
        allQ,
      };
    });
    data = _.sortBy(data, ["percent"]).reverse();
    return data;
  }, [submissions]);
  return (
    <Box
      sx={{
        padding: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: 120,
              }}
            >
              <Button
                onClick={() => {
                  navigate(ROUTES.TEACHER_VOCA_ASSIGNMENT);
                }}
                variant="contained"
                color="secondary"
              >
                Back
              </Button>
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                Assignment #{assignment?.code ?? ""}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginBottom: "8px",
                  fontSize: "14px",
                }}
              >
                Vocabulary Exercise Submission Tracking
              </Typography>
            </Box>
            <Box
              sx={{
                width: 120,
              }}
            ></Box>
          </Box>
        </Grid>
        {submissionsViewing.map((item) => {
          const percent = item.percent;
          const colorByPercent =
            percent > 80
              ? "#578FCA"
              : percent > 60
              ? "#16C47F"
              : percent > 40
              ? "#F0A04B"
              : percent > 20
              ? "#FADA7A"
              : "#B82132";
          return (
            <Grid
              key={item._id}
              item
              xs={4}
              sx={{
                padding: "8px",
              }}
            >
              <Paper
                sx={{
                  padding: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "8px",
                  }}
                >
                  <Avatar
                    sx={{
                      height: "48px",
                      width: "48px",
                      marginRight: "5px",
                    }}
                    src={
                      item?.student?.avatarPath ? `${getUrlServer(item?.student?.avatarPath)}` : "/images/people.png"
                    }
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {item.student?.username ?? ""}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontStyle: "italic",
                      }}
                    >
                      {item.student?.email ?? ""}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: colorByPercent,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {item.correctAns}/{item.allQ}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "6px",
                    borderWidth: 0.5,
                    borderColor: colorByPercent,
                    borderRadius: "4px",
                    overflow: "hidden",
                    transition: "border-color 0.3s ease-in-out",
                  }}
                >
                  <Box
                    sx={{
                      width: `${percent}%`,
                      height: "100%",
                      backgroundColor: colorByPercent,
                      borderRadius: "4px",
                      overflow: "hidden",
                      transition: "width 0.6s ease-in-out, background-color 0.3s ease-in-out",
                    }}
                  ></Box>
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    textAlign: "right",
                    marginTop: "8px",
                    fontStyle: "italic",
                  }}
                >
                  {item.submittedAt ? moment(item.submittedAt).format("lll") : ""}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default VocaSubmissionTracking;
