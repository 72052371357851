import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import LearnVoca from "@/pages/WordbookPage/components/LearnVoca";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import ListVocabsListView from "@/pages/WordbookPage/components/ListVocabsListView";
import ListVocabsGridView from "@/pages/WordbookPage/components/ListVocabsGridView";
const StudentVocaSubmisstion = () => {
  const theme = useTheme();
  const { assignmentId } = useParams();
  const [assignment, setAssignment] = useState(null);
  const [mySubmission, setMySubmission] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isViewList, setIsViewList] = useState(true);
  const [isDoingHomework, setIsDoingHomework] = useState(false);

  useEffect(() => {
    getDataAssignment();
    getShowSubmission();
  }, []);

  const getDataAssignment = async () => {
    const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_VOCA_ASSIGNMENT.replace(":id", assignmentId)));
    if (dataRes) {
      setAssignment(dataRes);
    }
  };

  const getShowSubmission = async () => {
    const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_MY_SUBMISSION.replace(":id", assignmentId)));
    // console.log("dataRes", dataRes);
    if (dataRes) {
      setMySubmission(dataRes);
    } else {
      setMySubmission(null);
    }
  };

  const onChangeAnswers = async (answers: any) => {
    setLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.SAVE_VOCA_SUBMISSION);

    try {
      const resData = await Requests.post(apiUrl, {
        answers: answers,
        assignment: assignmentId,
      });
      console.log("resData", resData);
    } catch (e) {
      console.log("e", e);
    }
    setLoading(false);
  };
  const initItems = useMemo(() => {
    if (mySubmission === undefined) return null;
    const items = assignment?.vocabs?.map((item) => ({
      ...item,
      answers: mySubmission?.answers?.[item._id],
    }));
    return items;
  }, [mySubmission, assignment]);
  return (
    <Box>
      {assignment ? (
        <Box
          sx={{
            padding: "16px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "24px",
              textAlign: "center",
              marginBottom: "16px",
            }}
          >
            Assignment #{assignment?.code ?? ""}
          </Typography>
          {isDoingHomework ? (
            <>
              <Box>
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "unset",
                  }}
                  onClick={() => {
                    setIsDoingHomework(false);
                  }}
                >
                  Back
                </Button>
              </Box>
              {initItems && (
                <LearnVoca items={initItems} learnTypes={assignment.learnType} onChangeAnswers={onChangeAnswers} />
              )}
            </>
          ) : (
            <>
              <Box
                sx={{
                  pading: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box
                    onClick={() => {
                      setIsViewList(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      padding: "0 4px",
                      ...(isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
                    }}
                  >
                    <ViewListIcon
                      sx={{
                        ...(isViewList ? { color: theme.palette.primary.main } : {}),
                      }}
                    />
                  </Box>
                  <Box
                    onClick={() => {
                      setIsViewList(false);
                    }}
                    sx={{
                      cursor: "pointer",
                      padding: "0 4px",
                      ...(!isViewList ? { borderBottom: "2px solid", borderColor: theme.palette.primary.main } : {}),
                    }}
                  >
                    <GridViewIcon
                      sx={{
                        ...(!isViewList ? { color: theme.palette.primary.main } : {}),
                      }}
                    />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "unset",
                  }}
                  onClick={() => {
                    setIsDoingHomework(true);
                  }}
                >
                  Do homework
                </Button>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 120px)",
                }}
              >
                {isViewList ? <ListVocabsListView items={initItems} /> : <ListVocabsGridView items={initItems} />}
              </Box>
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default StudentVocaSubmisstion;
