import { Button } from "@mui/material";
interface AppButtonProps {
  text: string;
  onClick: () => void;
}
const AppButton = ({ text, onClick }: AppButtonProps) => {
  return (
    <Button
      sx={{
        minWidth: "68px",
        height: "30px",
        padding: "0px 8px",
        borderRadius: "4px",
        color: "white",
        backgroundColor: "#264653",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "30px",
        textTransform: "none",
        marginLeft: "16px",
      }}
      variant="contained"
      onClick={() => onClick()}
    >
      {text}
    </Button>
  );
};

export default AppButton;
