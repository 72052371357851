import { IApplicationState, IItems, parseParamUrl, removeIntoCart } from "@/store-redux";
import { Box, Button, IconButton, Tab, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "@/constants";

const MyIdeaPath = () => {
  const theme = useTheme();
  const { questionId } = useParams();
  const [searchParams] = useSearchParams();
  const typeQuestion = searchParams.get("type-question");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const onRemoveIdea = (idea: IItems) => {
    dispatch(removeIntoCart(idea));
  };

  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 10px",
          paddingRight: 0,
          height: "50px",
          position: "fixed",
          top: "16px",
          right: "16px",
        }}
      >
        {listMyIdeaPath.data?.length > 0 && (
          <Button
            variant="contained"
            sx={{
              background: theme.palette.primary.main,
              color: "#fff",
              textTransform: "unset",
              height: "37px",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
            onClick={() => {
              const url = parseParamUrl(ROUTES.ANSWER_QUESTION.replace(":questionId", questionId ?? ""), undefined, {
                "type-question": typeQuestion,
              });
              navigate(url);
            }}
          >
            Customize your answer
          </Button>
        )}
      </Box>
      <Box
        sx={{
          padding: "0 10px",
          overflowY: "auto",
          maxHeight: "calc(100vh - 150px)",
          "& .MuiTabPanel-root": {
            padding: 0,
          },
          "& .MuiTabs-flexContainer": {},
          "& .MuiTabs-indicator": {
            display: "none",
          },
        }}
      >
        {listMyIdeaPath.data?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#6A6969",
                fontSize: "14px",
                marginRight: "5px",
              }}
            >
              Ý tưởng đã chọn
            </Typography>
            <Box
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: "50%",
                padding: "0 5px",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "18px",
              }}
            >
              {listMyIdeaPath.data?.length}
            </Box>
          </Box>
        ) : null}
        <Box>
          {listMyIdeaPath.data?.map((idea, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "5px 0",
                  ...(index !== listMyIdeaPath.data.length - 1 ? { borderBottom: "1px solid #D9D9D9" } : {}),
                }}
                key={index}
              >
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "14px!important",
                      fontWeight: "bold",
                      "& *": {
                        fontSize: "14px !important",
                      },
                    }}
                    className="title"
                    dangerouslySetInnerHTML={{ __html: idea.name?.replace(/\d+\.\s*&nbsp;/, "") || "" }}
                  />
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                    className="description"
                    dangerouslySetInnerHTML={{ __html: idea.description || "" }}
                  />
                </Box>
                <IconButton
                  sx={{
                    marginLeft: "5px",
                  }}
                  onClick={() => onRemoveIdea(idea)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default MyIdeaPath;
