import { AllTypeActions } from "../types";
import { IUser } from "../../common/types/user";

export interface AuthState {
  registerResponse: {
    data?: IUser;
    loading: boolean;
    error?: any;
  };
}

const initialState: AuthState = {
  registerResponse: {
    loading: false,
    data: undefined,
    error: undefined,
  },
};

export const RegisterPublicReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AllTypeActions.POST_REGISTER_PUBLIC:
      return {
        ...state,
        registerResponse: {
          loading: true,
          data: undefined,
          error: undefined,
        },
      };
    case AllTypeActions.REGISTER_PUBLIC_SUCCESS:
      return {
        ...state,
        registerResponse: {
          loading: false,
          data: action.data,
          error: undefined,
        },
      };
    case AllTypeActions.REGISTER_PUBLIC_FAILURE:
      return {
        ...state,
        registerResponse: {
          loading: false,
          data: undefined,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
